import PropTypes from "prop-types";
import { createContext, useContext, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { toastIdsFromError } from "../util/apolloErrorHandlers";
import Toasts from "./Toasts";

const Context = createContext();

const TIMEOUT = 10000;

const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState({});

  // id can be one or array
  const removeToast = (id) => {
    [].concat(id).forEach((i) =>
      setToasts((oldToasts) => {
        const newToasts = { ...oldToasts };
        delete newToasts[i];
        return newToasts;
      })
    );
  };

  const addToast = (toast, opts = {}) => {
    const id = opts.id || uuid();
    setToasts((oldToasts) => ({ ...oldToasts, [id]: toast }));
    if (!opts.sticky)
      setTimeout(() => removeToast(id), opts.timeout || TIMEOUT);

    return id;
  };

  // do not add `toasts` to the memo args. can cause bad loops. -ca
  const value = useMemo(
    () => ({
      addToast,
      removeToast,
      removeErrorToast: (error) => removeToast(toastIdsFromError(error)),
    }),
    [setToasts]
  );

  return (
    <>
      <Context.Provider value={value}>{children}</Context.Provider>
      <Toasts toasts={toasts} onClose={removeToast} />
    </>
  );
};

ToastContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useToasts = () => useContext(Context);
export default ToastContextProvider;
