import { gql } from "@apollo/client";

export const GET_BACKGROUND_JOBS = gql`
  query GetAllBackgroundJobs($state: String) {
    getAllBackgroundJobs(state: $state) {
      id
      state
      type
      input {
        __typename
        ... on CreateReleaseDocuments {
          releaseId
        }
        ... on CreateValidationDocument {
          suiteResultId
        }
        ... on GhostInspectorSync {
          folderId
          startDate
          endDate
        }
      }
    }
  }
`;

export const GET_BACKGROUND_JOB = gql`
  query GetBackgroundJob($id: ID!) {
    getBackgroundJob(id: $id) {
      id
      state
      type
    }
  }
`;
