import gql from "graphql-tag";

export const userFields = gql`
  fragment UserFields on User {
    id
    name
  }
`;

export const ME = gql`
  query Me {
    me {
      ...UserFields
    }
  }
  ${userFields}
`;
