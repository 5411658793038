import orderBy from "lodash/orderBy";

// groups each part of the release name
const releaseRegex = /(Q[1-4]).*([0-9]{4}).*(\d)/;

export const sortReleases = (releases) =>
  orderBy(
    releases || [],
    (release) => {
      const { name } = release;
      try {
        const [, quarter, year, releaseNumber] = name.match(releaseRegex);
        return [parseFloat(year), quarter, parseFloat(releaseNumber)];
      } catch (e) {
        return [name, name, name];
      }
    },
    "desc"
  );

export default { sortReleases };
