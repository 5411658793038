import { useEffect } from "react";
import { useAuth } from "./Auth0Wrapper";

// FIXME: this should be different, no sign out via passive GET

const SignOut = () => {
  const { client } = useAuth();

  useEffect(() => {
    client?.logout({ logoutParams: { returnTo: window.location.origin } });
  }, [client]);

  return "";
};

export default SignOut;
