import PropTypes from "prop-types";
import { createContext, useContext, useMemo, useState } from "react";
import { Button, Table } from "reactstrap";
import Icon from "../Icon";

const Context = createContext();
export const useSortableTable = () => useContext(Context);

export const SortHeader = ({
  children,
  additionalChildren,
  sortKey,
  ...restProps
}) => {
  const {
    sortKey: currentSortKey,
    setSortKey,
    reverse,
    setReverse,
  } = useSortableTable();

  const onClick = () => {
    if (sortKey === currentSortKey) setReverse(!reverse);
    else {
      setReverse(false);
      setSortKey(sortKey);
    }

    if (restProps.onClick) restProps.onClick();
  };

  const className = `kv-sortable-table__header cursor-pointer ${
    restProps.className || ""
  }`;

  return (
    <th scope="col" {...{ ...restProps, className }}>
      <div className="d-flex">
        <Button color="plain" onClick={onClick}>
          {children}
          {sortKey === currentSortKey ? (
            <Icon
              icon={reverse ? "Desc" : "Asc"}
              option="fad"
              className="ps-1"
            />
          ) : (
            <Icon icon="Asc" className="ps-1 invisible" />
          )}
        </Button>
        {additionalChildren}
      </div>
    </th>
  );
};

SortHeader.defaultProps = { additionalChildren: "" };
SortHeader.propTypes = {
  children: PropTypes.node.isRequired,
  additionalChildren: PropTypes.node,
  sortKey: PropTypes.string.isRequired,
};

const SortableTable = ({
  children,
  defaultSortKey,
  defaultReverse,
  ...tableProps
}) => {
  const [sortKey, setSortKey] = useState(defaultSortKey);
  const [reverse, setReverse] = useState(defaultReverse);
  const [sortFn, setSortFn] = useState();

  const value = useMemo(
    () => ({ sortKey, setSortKey, sortFn, setSortFn, reverse, setReverse }),
    [sortKey, sortFn, reverse]
  );

  return (
    <Context.Provider value={value}>
      <Table {...tableProps}>{children}</Table>
    </Context.Provider>
  );
};

SortableTable.defaultProps = {
  defaultSortKey: undefined,
  defaultReverse: false,
};
SortableTable.propTypes = {
  children: PropTypes.node.isRequired,
  defaultSortKey: PropTypes.string,
  defaultReverse: PropTypes.bool,
};

export default SortableTable;
