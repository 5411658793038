// These are here because there is a single row in the table
// that needs to stop propagation on a td element.
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { gql, useMutation } from "@apollo/client";
import orderBy from "lodash/orderBy";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button, Table } from "reactstrap";
import { displayDateTime } from "../../../util/dateUtils";
import Icon from "../../shared/Icon";
import KvButton from "../../shared/KvButton";
import PendingIcon from "../../shared/PendingIcon";
import Result from "../../shared/Result";
import ResultTable from "./ResultTable";
import ValidationDocument from "./ValidationDocument";

const SYNC_TEST_SUITE = gql`
  mutation SyncTestSuite($id: ID!) {
    syncTestSuite(id: $id)
  }
`;

const SuiteResultsTable = ({
  suiteResults,
  refetch,
  selectedSuiteResults,
  selectSuiteResult,
}) => {
  const [showAll, setShowAll] = useState(false);
  const [syncTestSuite, { loading }] = useMutation(SYNC_TEST_SUITE, {
    variables: { id: suiteResults[0]?.testSuiteId },
    skip: !suiteResults.length,
  });
  const [selectedSuiteResultId, setSelectedSuiteResultId] = useState();
  const sortedResults = orderBy(
    suiteResults,
    (r) => new Date(r.executionStartedAt),
    ["desc"]
  );
  const results = showAll ? sortedResults : sortedResults.slice(0, 10);

  const handleExpandClick = (e, suiteResultId) => {
    setSelectedSuiteResultId((prev) =>
      prev === suiteResultId ? undefined : suiteResultId
    );
  };

  const onSelect = (e, result) => {
    selectSuiteResult(result);
  };

  return (
    <>
      <KvButton
        className="ms-auto"
        onClick={syncTestSuite}
        isLoading={loading}
        icon="Sync"
        color="plain-blue"
      >
        Sync Results
      </KvButton>
      <Table borderless>
        <thead>
          <tr>
            <th aria-label="expand-row" />
            <th aria-label="select-row" />
            <th>Started At</th>
            <th>Release</th>
            <th>Status</th>
            <th>Tests Passed</th>
            <th>Tests Failed</th>
            <th>Tests In Progress</th>
            <th className="text-center">Doc</th>
          </tr>
        </thead>
        {results.map((result) => (
          <tbody key={result.id}>
            <tr
              onClick={(e) => handleExpandClick(e, result.id)}
              className="kv-clickable-row"
            >
              <td>
                <Button color="plain">
                  <Icon
                    icon={
                      selectedSuiteResultId === result.id
                        ? "Collapse"
                        : "Expand"
                    }
                  />
                </Button>
              </td>
              <td onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  checked={selectedSuiteResults.includes(result)}
                  onChange={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    onSelect(e, result);
                  }}
                />
              </td>
              <td>{displayDateTime(result.executionStartedAt)}</td>
              <td>{result.release?.name}</td>
              <td>
                {result.passing != null ? (
                  <Result isPassing={result.passing} />
                ) : (
                  <div>
                    <PendingIcon />
                    <span className="ms-2">Running</span>
                  </div>
                )}
              </td>
              <td>{result.countPassing}</td>
              <td>{result.countFailing}</td>
              <td>{result.countUnknown}</td>
              <td align="center">
                <ValidationDocument
                  doc={result.validationDocument}
                  refetchResult={refetch}
                  suiteResultId={result.id}
                />
              </td>
            </tr>
            {selectedSuiteResultId === result.id && (
              <tr>
                <td />
                <td colSpan={5}>
                  <ResultTable suiteResultId={result.id} />
                </td>
              </tr>
            )}
          </tbody>
        ))}
      </Table>
      {!showAll && (
        <KvButton onClick={() => setShowAll(true)} color="link">
          Show All Results
        </KvButton>
      )}
    </>
  );
};

SuiteResultsTable.propTypes = {
  suiteResults: PropTypes.arrayOf(
    PropTypes.shape({
      passing: PropTypes.bool,
      executionStartedAt: PropTypes.string,
      countPassing: PropTypes.number,
      countFailing: PropTypes.number,
      countUnknown: PropTypes.number,
      testSuiteId: PropTypes.string,
    })
  ).isRequired,
  refetch: PropTypes.func.isRequired,
  selectSuiteResult: PropTypes.func.isRequired,
  selectedSuiteResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ).isRequired,
};

export default SuiteResultsTable;
