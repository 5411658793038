import { Link } from "react-router-dom";

const NotFound = () => (
  <div className="kv-main">
    <h2>Not Found</h2>
    <p>We could not find the resource you requested.</p>
    <p>
      <Link to="/">Return to the home page.</Link>
    </p>
  </div>
);
export default NotFound;
