import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EmptyTable from "../shared/EmptyTable";
import KvButton from "../shared/KvButton";
import SimpleTable from "../shared/SimpleTable/SimpleTable";
import CreateModal from "./CreateModal";
import DeleteModal from "./DeleteModal";

const GET_TEST_PLANS = gql`
  query GetAllTestPlans {
    getAllTestPlans {
      id
      name
    }
  }
`;

const TestRunner = () => {
  const [showCreate, setShowCreate] = useState(false);
  const [deletePlan, setDeletePlan] = useState();
  const { data } = useQuery(GET_TEST_PLANS);
  const navigate = useNavigate();

  const editPlan = (id) => {
    navigate(`test-plan/${id}`);
  };

  const editCell = (row) => (
    <>
      <KvButton
        name="Edit"
        onClick={() => editPlan(row.id)}
        icon="Edit"
        color="plain"
      />
      <KvButton
        name="Delete"
        className="ms-3"
        onClick={() => setDeletePlan(row)}
        icon="Delete"
        color="plain"
      />
    </>
  );

  const tableHeaders = [
    { displayName: "Name", key: "name" },
    { displayName: "Steps", key: "testSequenceSteps.length" },
    {
      displayName: "Edit",
      key: "edit",
      formatFn: editCell,
    },
  ];

  return (
    <div className="kv-main--wrapper__scrollable">
      <div
        className="kv-main container-fluid kv-home pb-4 kv-test-runner"
        role="main"
      >
        <div className="d-flex justify-content-between">
          <h3>Test Runner</h3>
          <KvButton icon="Plus" onClick={() => setShowCreate(true)}>
            Create Test Plan
          </KvButton>
        </div>
        <SimpleTable headers={tableHeaders} entries={data?.getAllTestPlans} />
        <EmptyTable collection={data?.getAllTestPlans}>
          No test plans created yet
        </EmptyTable>
        <CreateModal
          isOpen={showCreate}
          onCancel={() => setShowCreate(false)}
        />
        <DeleteModal
          isOpen={!!deletePlan}
          testPlan={deletePlan}
          onCancel={() => setDeletePlan()}
        />
      </div>
    </div>
  );
};

export default TestRunner;
