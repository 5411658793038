import gql from "graphql-tag";

export const GET_LOOKER_DASHBOARDS = gql`
  query GetLookerDashboards($env: String!) {
    getLookerDashboards(env: $env) {
      title
      id
      folderName
      path
      slug
    }
  }
`;

export const SYNC_LOOKER_DASHBOARDS = gql`
  mutation SyncLookerDashboards(
    $targetEnv: String!
    $sourceEnv: String!
    $dashboardIds: [String!]!
  ) {
    syncLookerDashboards(
      targetEnv: $targetEnv
      sourceEnv: $sourceEnv
      dashboardIds: $dashboardIds
    )
  }
`;
