import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import KvModalFooter from "./KvModalFooter";

const KvModal = ({
  onCancel,
  onConfirm,
  confirm,
  loading,
  header,
  children,
  confirmColor,
  disabled,
  ...rest
}) => (
  <Modal toggle={onCancel} centered {...rest}>
    <ModalHeader toggle={onCancel}>{header}</ModalHeader>
    <ModalBody>{children}</ModalBody>
    <KvModalFooter
      {...{ onCancel, onConfirm, confirm, loading, confirmColor, disabled }}
    />
  </Modal>
);

KvModal.defaultProps = {
  ...KvModalFooter.defaultProps,
};

KvModal.propTypes = {
  ...KvModalFooter.propTypes,
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default KvModal;
