import { Auth0Client } from "@auth0/auth0-spa-js";
import { getItem, removeItem, setItem } from "../../../util/localStorage";

const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const callbackRoute = "/auth/callback";
const publicRoutes = [
  "/no-user",
  "/sign-out",
  "/sign-in",
  "/status",
  "/auth/reset",
];
export const routeIsPublic = () =>
  publicRoutes.includes(window.location.pathname);
export const routeIsCallback = () => window.location.pathname === callbackRoute;

export const createAuthClient = async () =>
  new Auth0Client({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    scope: "all",
    cacheLocation: "localstorage",
    authorizationParams: {
      audience,
      redirect_uri: `${window.location.origin}/auth/callback`,
    },
  });

const LAST_LOCATION = "last-location";
export const stashLastLocation = () => {
  const { pathname, search, hash } = window.location;
  const loc = [pathname, search, hash].join("");
  if (!loc || ["/", "/sign-out"].includes(loc)) return;
  setItem(LAST_LOCATION, loc);
};

export const fetchLastLocation = () => {
  const loc = getItem(LAST_LOCATION);
  removeItem(LAST_LOCATION);
  return loc;
};

export const handleAuth = async (client) => {
  const isAuthenticated = await client.isAuthenticated();
  let token;

  if (!routeIsPublic()) {
    if (!isAuthenticated) {
      stashLastLocation();
      await client.loginWithRedirect();
    }

    try {
      token = await client.getTokenSilently({ detailedResponse: true });
    } catch (e) {
      console.warn("Error getting token");
      throw new Error("Login required");
    }
  }

  return { client, token, isAuthenticated: await client.isAuthenticated() };
};

export default { handleAuth };
