import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { START_MANAGED_RUN } from "./data";
import { managedRunShape } from "../shared/shapes";
import { Icon, pluralize } from "../shared";
import KvButton from "../shared/KvButton";

const RunTests = ({ managedRun }) => {
  const [runTests, { loading }] = useMutation(START_MANAGED_RUN);

  const onClick = () => {
    runTests({ variables: { id: managedRun.id } });
  };

  const testCount = managedRun.testIds?.length;
  const suiteCount = managedRun.suiteIds?.length;

  const text = [];
  if (suiteCount > 0) text.push(`${pluralize(suiteCount, "suite")}`);
  if (testCount > 0) text.push(`${pluralize(testCount, "test")}`);

  const copy = text.length ? (
    <>
      <Icon icon="rocket" />
      Run {text.join(" and ")}
    </>
  ) : (
    "Select Tests or Suites"
  );

  return (
    <KvButton isLoading={loading} disabled={!text.length} onClick={onClick}>
      {copy}
    </KvButton>
  );
};

RunTests.propTypes = {
  managedRun: PropTypes.shape(managedRunShape).isRequired,
};
export default RunTests;
