import PropTypes from "prop-types";
import { Button, ModalFooter } from "reactstrap";
import KvButton from "./KvButton";

const KvModalFooter = ({
  cancel,
  onCancel,
  confirm,
  onConfirm,
  loading,
  disabled,
  confirmColor,
}) => (
  <ModalFooter>
    {onCancel && (
      <Button color="plain" onClick={onCancel}>
        {cancel}
      </Button>
    )}
    {onConfirm && (
      <KvButton
        isLoading={loading}
        onClick={onConfirm}
        disabled={disabled}
        color={confirmColor}
      >
        {confirm}
      </KvButton>
    )}
  </ModalFooter>
);

KvModalFooter.defaultProps = {
  onCancel: undefined,
  onConfirm: undefined,
  loading: false,
  disabled: false,
  cancel: "Cancel",
  confirm: "Confirm",
  confirmColor: undefined,
};

KvModalFooter.propTypes = {
  cancel: PropTypes.string,
  confirm: PropTypes.string,
  confirmColor: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default KvModalFooter;
