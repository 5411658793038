import PropTypes from "prop-types";
import { Button, CardImg } from "reactstrap";
import Icon from "./Icon";

const VideoThumbnail = ({ imgSrc, onClick }) => (
  <Button color="plain" onClick={onClick}>
    <div className="position-relative">
      <CardImg src={imgSrc} className="opacity-50" />
      <div>
        <Icon
          icon="Play"
          size={3}
          solid
          className="position-absolute top-50 start-50 translate-middle"
        />
      </div>
    </div>
  </Button>
);

VideoThumbnail.defaultProps = {
  imgSrc: "",
};

VideoThumbnail.propTypes = {
  imgSrc: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default VideoThumbnail;
