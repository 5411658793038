import PropTypes from "prop-types";
import Icon from "./Icon";

const ResultIcon = ({ isPassing }) =>
  isPassing == null ? undefined : (
    <Icon
      icon={isPassing ? "PassingResult" : "FailingResult"}
      className={isPassing ? "text-success" : "text-danger"}
      solid
    />
  );

ResultIcon.defaultProps = {
  isPassing: undefined,
};

ResultIcon.propTypes = {
  isPassing: PropTypes.bool,
};

export default ResultIcon;
