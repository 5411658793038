import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useState } from "react";
import { Button, Table } from "reactstrap";
import { displayDateTime, Icon } from "../shared";
import Drawer from "../shared/Drawer";
import CreateManagedRun from "./CreateManagedRun";
import { GET_MANAGED_RUNS } from "./data";
import ManagedRun from "./ManagedRun";
import "./managedRuns.scss";

const ManagedRuns = () => {
  const { data } = useQuery(GET_MANAGED_RUNS);
  const [create, setCreate] = useState(false);
  const [currentRun, setCurrentRun] = useState(false);

  return (
    <div className="kv-main--wrapper__scrollable">
      <div className="kv-main container-fluid kv-home pb-4" role="main">
        <div className="d-flex">
          <h3>Managed Runs</h3>
          <Button
            color="primary"
            onClick={() => setCreate(true)}
            className="ms-auto"
          >
            <Icon icon="Create" />
            Create Managed Run
          </Button>
        </div>

        <Table>
          <thead>
            <tr>
              <th>Created At</th>
              <th>Name</th>
              <th>Status</th>
              <th>Folder</th>
            </tr>
          </thead>
          <tbody>
            {_.sortBy(data?.getAllManagedRuns)
              .reverse()
              .map((run) => (
                <tr
                  key={run.id}
                  tabIndex="0"
                  onClick={() => setCurrentRun(run)}
                >
                  <td>{displayDateTime(run.createdAt)} </td>
                  <td>{run.name} </td>
                  <td>{run.status} </td>
                  <td>{run.folder?.name}</td>
                </tr>
              ))}
          </tbody>
        </Table>

        <CreateManagedRun isOpen={create} closeModal={() => setCreate(false)} />
        <Drawer isIn={!!currentRun} className="kv-managed-run-drawer">
          {currentRun && (
            <ManagedRun
              managedRunId={currentRun.id}
              onExit={() => setCurrentRun(undefined)}
            />
          )}
        </Drawer>
      </div>
    </div>
  );
};

export default ManagedRuns;
