import { useEffect, useMemo, useState } from "react";

// https://stackoverflow.com/a/65008608/346527

export default function useIsVisible(ref) {
  const [visible, setVisible] = useState(false);

  const observer = useMemo(() => {
    if (!window.IntersectionObserver) {
      setVisible(true);
      return undefined;
    }

    return new IntersectionObserver(([entry]) =>
      setVisible(entry.isIntersecting)
    );
  }, [ref]);

  useEffect(() => {
    observer?.observe(ref.current);
    return () => observer?.disconnect();
  }, []);

  return visible;
}
