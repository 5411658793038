import PropTypes from "prop-types";
import { Alert } from "reactstrap";

const SimpleAlert = ({ alert, ...rest }) => {
  if (!alert) return "";

  return (
    <Alert color={alert[0]} className="m-4" {...rest}>
      {alert[1]}
    </Alert>
  );
};

SimpleAlert.defaultProps = { alert: undefined };
SimpleAlert.propTypes = { alert: PropTypes.arrayOf(PropTypes.node) };
export default SimpleAlert;
