import { gql, useQuery } from "@apollo/client";
import _ from "lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Drawer from "../shared/Drawer";
import KvButton from "../shared/KvButton";
import KvModal from "../shared/KvModal";
import SuiteSelector from "./SuiteSelector";
import SuiteWorkflow from "./SuiteWorkflow";
import "./TestRunner.scss";
import TestSequence from "./TestSequence";

const GET_TEST_PLAN = gql`
  query GetTestPlan($id: ID!) {
    getTestPlan(id: $id) {
      id
      name
      sequenceSteps {
        id
        sequenceIndex
        stepPosition
        testSuite {
          id
          name
          ghostId
          folder {
            id
            name
          }
        }
      }
    }
  }
`;

const EditTestPlan = () => {
  const [selectedSeqIndex, setAddToSequenceId] = useState();
  const [showWorkflow, setShowWorkflow] = useState(false);
  const { testPlanId } = useParams();
  const navigate = useNavigate();
  const { data } = useQuery(GET_TEST_PLAN, { variables: { id: testPlanId } });

  const sequenceSteps = data?.getTestPlan?.sequenceSteps || [];
  const sequences = _.uniq(sequenceSteps.map((step) => step.sequenceIndex));
  // add an extra one so user can create a new sequence
  sequences.push(sequences.length);

  const navigateToTestPlans = () => navigate("/test-runner");

  return (
    <div className="kv-main--wrapper__scrollable">
      <div
        className="kv-main container-fluid kv-home pb-4 kv-test-runner"
        role="main"
      >
        <div className="d-flex">
          <div>
            <h3>Test Runner</h3>
            <KvButton
              onClick={navigateToTestPlans}
              color="link"
              className="ms-0 ps-0"
            >
              ← Back to Test Plans
            </KvButton>
          </div>
          <KvButton
            wrapperClassName="ms-auto"
            useWrapper
            onClick={() => setShowWorkflow(true)}
          >
            Show Workflow Yaml
          </KvButton>
        </div>
        <div className="d-flex gap-5 mt-5">
          {sequences.map((sequence) => (
            <TestSequence
              key={sequence}
              sequenceIndex={sequence}
              steps={sequenceSteps.filter(
                (step) => step.sequenceIndex === sequence
              )}
              onAddTests={() => setAddToSequenceId(sequence)}
            />
          ))}
          <KvModal
            header="Test Plan Workflow"
            isOpen={showWorkflow}
            onCancel={() => setShowWorkflow(false)}
            onConfirm={() => setShowWorkflow(false)}
          >
            <SuiteWorkflow steps={sequenceSteps} />
          </KvModal>
          <Drawer
            isIn={selectedSeqIndex != null}
            onClickOutside={() => setAddToSequenceId()}
            className="test-suite-drawer"
          >
            <SuiteSelector sequenceIndex={selectedSeqIndex} />
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default EditTestPlan;
