import { BrowserRouter } from "react-router-dom";
import Layout from "./Layout/Layout";
import ApolloWrapper from "./Layout/ApolloWrapper";
import { AuthWrapper } from "./Components/Auth";
import UserContextProvider from "./Layout/UserContextProvider";
import ToastContextProvider from "./Layout/ToastContextProvider";

import Routes from "./Routes";
import "./App.scss";

const App = () => (
  <BrowserRouter>
    <ToastContextProvider>
      <AuthWrapper>
        <ApolloWrapper>
          <UserContextProvider>
            <Layout>
              <Routes />
            </Layout>
          </UserContextProvider>
        </ApolloWrapper>
      </AuthWrapper>
    </ToastContextProvider>
  </BrowserRouter>
);

export default App;
