export const errorIsForbidden = ({ error }) =>
  error && error.graphQLErrors[0]?.extensions.code === "FORBIDDEN";

export const toastIdsFromError = (error) => {
  const ids = [error.toastId];
  ids.push(...(error.graphQLErrors || []).map((e) => e.toastId));
  return ids.filter((i) => i);
};

export default {
  errorIsForbidden,
};
