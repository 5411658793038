import PropTypes from "prop-types";
import { useState } from "react";
import { Button } from "reactstrap";
import { Icon } from "../shared";
import { managedRunShape } from "../shared/shapes";
import CreateManagedRun from "./CreateManagedRun";

const DuplicateRun = ({ run }) => {
  const [active, setActive] = useState(false);
  return (
    <>
      <Button
        color="primary"
        onClick={() => setActive(true)}
        className="float-end"
      >
        <Icon icon="Copy" />
        Duplicate Run
      </Button>
      <CreateManagedRun
        closeModal={() => setActive(false)}
        isOpen={active}
        sourceRun={run}
      />
    </>
  );
};

DuplicateRun.propTypes = { run: PropTypes.shape(managedRunShape).isRequired };

export default DuplicateRun;
