import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "reactstrap";
import { sortReleases } from "../../util/releaseUtils";
import NewReleaseForm from "./NewReleaseForm";
import Release from "./Release";
import ReleaseNav from "./ReleaseNav";

const GET_ALL_RELEASES = gql`
  query GetAllReleases($filter: DateRange) {
    getAllReleases(filter: $filter) {
      id
      webCompareLink
      webCommitSha
      createdAt
      updatedAt
      releaseDate
      name
      active
      apiCommitSha
      apiCompareLink
    }
  }
`;

const Releases = () => {
  const [creating, setCreating] = useState();
  const [searchParams] = useSearchParams();

  const year = searchParams.get("year");
  const { data } = useQuery(GET_ALL_RELEASES, {
    variables: {
      filter: {
        startDate: year ? `${year}-01-01` : undefined,
        endDate: year ? `${year}-12-31` : undefined,
      },
    },
  });

  return (
    <div className="kv-main--wrapper__scrollable">
      <div className="kv-main container-fluid kv-home pb-4" role="main">
        <div className="d-flex">
          <h3>Releases</h3>
          {!creating && (
            <Button
              className="ms-5"
              color="primary"
              onClick={() => setCreating(true)}
            >
              Create
            </Button>
          )}
        </div>
        <ReleaseNav releases={data?.getAllReleases} className="my-3" />
        {creating && <NewReleaseForm onComplete={() => setCreating(false)} />}

        {sortReleases(data?.getAllReleases).map((r, idx) => (
          <div key={r.id} className="mt-4">
            <Release release={r} defaultExpanded={idx === 0} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Releases;
