import PropTypes from "prop-types";

export const baseShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
};

export const testShape = { ...baseShape };
export const testSuiteShape = { ...baseShape };
export const folderShape = { ...baseShape };
export const managedRunShape = { ...baseShape };
export const suiteResultShape = {
  ...baseShape,
  passing: PropTypes.bool,
  executionStartedAt: PropTypes.string,
  countPassing: PropTypes.number,
  countFailing: PropTypes.number,
  countUnknown: PropTypes.number,
};
