/* eslint-disable react/no-array-index-key */

import { gql, useQuery } from "@apollo/client";
import { Badge, Table } from "reactstrap";
import Loading from "../shared/Loading";

const GET_REPORT = gql`
  query GetLookerBoardReport {
    getLookerBoardReport
  }
`;

const infoForCell = (idx, row) => {
  if (idx !== row.length - 1) return "";

  const database = row[idx];
  if (!database.length)
    return <Badge color="secondary">Dashboard is empty</Badge>;

  if (database.split(",").length > 1)
    return <Badge color="danger">Invalid configuration: multiple envs</Badge>;

  return "";
};

const BoardReport = () => {
  const { data } = useQuery(GET_REPORT);

  if (!data) return <Loading full />;
  const rows = [...data.getLookerBoardReport];

  const header = rows.shift();
  return (
    <Table>
      <thead>
        <tr>
          {header.map((content, index) => (
            <th key={index}>{content}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index}>
            {row.map((content, idx) => (
              <td key={idx}>
                {content}
                {infoForCell(idx, row)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default BoardReport;
