import { useState } from "react";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";
import { Button, Table } from "reactstrap";
import { displayDateTime } from "../../util/dateUtils";
import Result from "../shared/Result";
import Icon from "../shared/Icon";
import { suiteResultShape } from "../shared/shapes";
import ResultTable from "../Tests/FolderReport/ResultTable";

const SuiteResults = ({ suiteResults }) => {
  const [selectedSuiteResultId, setSelectedSuiteResultId] = useState();
  const sortedResults = orderBy(
    suiteResults,
    (r) => displayDateTime(r.executionStartedAt),
    ["desc"]
  );

  const handleExpandClick = (e, suiteResultId) => {
    setSelectedSuiteResultId((prev) =>
      prev === suiteResultId ? undefined : suiteResultId
    );
  };

  return (
    <Table borderless>
      <thead>
        <tr>
          <th aria-label="expand-row" />
          <th>Suite</th>
          <th>Started At</th>
          <th>Status</th>
          <th>Tests Passed</th>
          <th>Tests Failed</th>
          <th>Tests In Progress</th>
        </tr>
      </thead>
      {sortedResults.map((result) => (
        <tbody key={result.id}>
          <tr
            onClick={(e) => handleExpandClick(e, result.id)}
            className="kv-clickable-row"
          >
            <td>
              <Button color="plain">
                <Icon
                  icon={selectedSuiteResultId === result.id ? "Hide" : "Reveal"}
                />
              </Button>
            </td>
            <td>{result.name}</td>

            <td>{displayDateTime(result.executionStartedAt)}</td>
            <td>
              <Result isPassing={result.passing} />
            </td>
            <td>{result.countPassing}</td>
            <td>{result.countFailing}</td>
            <td>{result.countUnknown}</td>
          </tr>
          {selectedSuiteResultId === result.id && (
            <tr>
              <td />
              <td colSpan={6} className="bg-white">
                <ResultTable suiteResultId={result.id} />
              </td>
            </tr>
          )}
        </tbody>
      ))}
    </Table>
  );
};

SuiteResults.propTypes = {
  suiteResults: PropTypes.arrayOf(PropTypes.shape(suiteResultShape)).isRequired,
};

export default SuiteResults;
