import PropTypes from "prop-types";
import ResultIcon from "./ResultIcon";

const Result = ({ isPassing }) => {
  if (isPassing == null) return undefined;
  return (
    <div>
      <ResultIcon isPassing={isPassing} />
      <span className="ms-2">{isPassing ? "Passing" : "Failing"}</span>
    </div>
  );
};

Result.defaultProps = {
  isPassing: undefined,
};

Result.propTypes = {
  isPassing: PropTypes.bool,
};

export default Result;
