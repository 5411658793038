import { createContext, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

const Context = createContext();

const ContextProvider = ({ children }) => {
  const [sourceEnv, setSourceEnv] = useState("sandbox");
  const [selectedDashboardIds, setSelectedDashboardIds] = useState([]);

  const value = useMemo(
    () => ({
      sourceEnv,
      setSourceEnv,
      selectedDashboardIds,
      setSelectedDashboardIds,
    }),
    [sourceEnv, selectedDashboardIds]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

ContextProvider.propTypes = { children: PropTypes.node.isRequired };

export const useLookerSync = () => useContext(Context);
export default ContextProvider;
