import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { CREATE_SNAPSHOT } from "./data";

const NewSnapshotForm = ({ onComplete }) => {
  const [snapshotAttrs, setSnapshotAttrs] = useState();
  const [createSnapshot] = useMutation(CREATE_SNAPSHOT, {
    refetchQueries: ["GetAllSnapshots"],
  });

  const handleChange = (value, property) => {
    setSnapshotAttrs((prev) => ({ ...prev, [property]: value }));
  };

  const create = () => {
    createSnapshot({ variables: { input: { ...snapshotAttrs } } });
    onComplete();
  };

  return (
    <Card className="w-25 mb-4">
      <form
        onSubmit={() => {
          create();
          return false;
        }}
      >
        <CardHeader>New Snapshot</CardHeader>
        <CardBody>
          <Container>
            <Row className="align-items-center">
              <Col>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    autoFocus
                    id="name"
                    onChange={(e) => handleChange(e.target.value, "name")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </CardBody>
        <CardFooter className="text-end">
          <Button type="button" color="plain" onClick={onComplete}>
            Cancel
          </Button>
          <Button className="ms-3" type="submit" color="primary">
            Create
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};

NewSnapshotForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
};

export default NewSnapshotForm;
