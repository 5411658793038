import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import { Icon, Loading } from "../shared";
import useClickOff from "../shared/hooks/useClickOutside";
import DuplicateRun from "./DuplicateRun";
import FolderTestSelectFields from "./FolderTestSelectFields";
import RunResults from "./RunResults";
import RunTests from "./RunTests";
import Tabs from "./Tabs";
import { GET_MANAGED_RUN, UPDATE_MANAGED_RUN } from "./data";

const ManagedRun = ({ managedRunId, onExit }) => {
  const ref = useRef();
  const [run, setRun] = useState();
  const [testIds, setTestIds] = useState([]);
  const [suiteIds, setSuiteIds] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [tab, setTab] = useState("suites");
  const { data } = useQuery(GET_MANAGED_RUN, {
    variables: { id: managedRunId },
  });
  const [updateRun] = useMutation(UPDATE_MANAGED_RUN);
  useClickOff(ref, onExit);

  useEffect(() => {
    if (data) {
      const r = data.getManagedRun;
      setRun(r);
      setTestIds(r.testIds);
      setSuiteIds(r.suiteIds);
      if (r.status !== "New" && !dirty) setTab("results");
    }
  }, [data]);

  useEffect(() => {
    if (!run || !dirty) return;

    const hasChanged =
      !_.isEqual(testIds, run.testIds) || !_.isEqual(suiteIds, run.suiteIds);

    if (hasChanged) {
      // maybe debounce?
      updateRun({
        variables: { id: run.id, managedRun: { testIds, suiteIds } },
      });
    }
  }, [testIds, suiteIds]);

  if (!run) return <Loading full />;

  const selectRunnable = (e, setter) => {
    const { checked, value } = e.target;
    if (!value) return;
    setter((t) => (checked ? _.union(t, [value]) : _.without(t, value)));
    setDirty(true);
  };
  const selectTest = (e) => selectRunnable(e, setTestIds);
  const selectSuite = (e) => selectRunnable(e, setSuiteIds);

  const selectedIds = tab === "suites" ? run.suiteIds : run.testIds;

  return (
    <div className="kv-managed-run" ref={ref}>
      <h2 className="d-flex">
        {run.name}
        <Button color="plain" onClick={onExit} className="ms-auto">
          <Icon icon="Close" />
        </Button>
      </h2>
      <h3 className="fs-5 mb-4 d-flex">
        Testing {run.folder.name}
        <div className="ms-auto">Status: {run.status}</div>
      </h3>

      {run.status !== "New" ? (
        <>
          <DuplicateRun run={run} />
          <RunResults run={run} />
        </>
      ) : (
        <>
          <Tabs {...{ tab, setTab }} />
          <div className="float-end">
            <RunTests managedRun={run} />
          </div>

          <FolderTestSelectFields
            folder={run.folder}
            selectedIds={selectedIds || []}
            onChange={tab === "suites" ? selectSuite : selectTest}
            scope={tab}
          />
        </>
      )}
    </div>
  );
};

ManagedRun.propTypes = {
  managedRunId: PropTypes.string.isRequired,
  onExit: PropTypes.func.isRequired,
};
export default ManagedRun;
