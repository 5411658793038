import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import { UncontrolledTooltip } from "reactstrap";
import { Icon, displayDate } from "../shared";

const ReleaseDetails = ({ release, onUpdate }) => (
  <>
    <h5 className="text-gray-medium">Details</h5>
    <div className="d-flex align-items-center">
      <div>
        Released: {release.releaseDate && displayDate(release.releaseDate)}
      </div>
      {!release.releaseDate && (
        <ReactDatePicker
          customInput={<Icon icon="Date" className="ms-3" />}
          onChange={(releaseDate) => onUpdate({ releaseDate })}
        />
      )}
    </div>
    <div>
      Web sha:{" "}
      <a href={release.webCompareLink} target="_blank" rel="noreferrer">
        {release.webCommitSha}
      </a>
    </div>
    <div>
      Api sha:{" "}
      <a href={release.apiCompareLink} target="_blank" rel="noreferrer">
        {release.apiCommitSha}
      </a>
    </div>
    <UncontrolledTooltip target={`delete-${release.id}`} placement="top-start">
      Delete Release {release.name}
    </UncontrolledTooltip>
  </>
);

ReleaseDetails.propTypes = {
  release: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    webCommitSha: PropTypes.string,
    apiCommitSha: PropTypes.string,
    webCompareLink: PropTypes.string,
    apiCompareLink: PropTypes.string,
    releaseDate: PropTypes.string,
    issues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        summary: PropTypes.string,
      })
    ),
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default ReleaseDetails;
