import PropTypes from "prop-types";
import { Input } from "reactstrap";
import "./IconCheckbox.scss";

const IconCheckbox = ({ className, ...props }) => (
  <Input
    type="checkbox"
    className={`kv-icon-checkbox ${className}`}
    {...props}
  />
);

IconCheckbox.defaultProps = { className: "" };
IconCheckbox.propTypes = { className: PropTypes.string };

export default IconCheckbox;
