import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import gql from "graphql-tag";
import { chain } from "lodash";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";

const GET_ALL_RELEASES_NAV = gql`
  query GetAllReleases($filter: DateRange) {
    getAllReleases(filter: $filter) {
      id
      createdAt
    }
  }
`;

const ReleaseNav = ({ ...rest }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data } = useQuery(GET_ALL_RELEASES_NAV);

  const releaseYears = chain(
    data?.getAllReleases?.map((r) => dayjs(r.createdAt).year())
  )
    .uniq()
    .orderBy((year) => year, ["desc"])
    .value();

  const currentYear = searchParams.get("year");

  const setYear = (year) => setSearchParams({ year });

  useEffect(() => {
    if (currentYear || !releaseYears.length) return;
    setYear(releaseYears[0]);
  }, []);

  return (
    <Nav tabs {...rest}>
      {releaseYears.map((year) => (
        <NavItem key={year}>
          <NavLink
            active={year.toString() === currentYear}
            href="#"
            onClick={() => setYear(year)}
          >
            {year}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

ReleaseNav.defaultProps = { releases: [] };

ReleaseNav.propTypes = {
  releases: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
    })
  ),
};

export default ReleaseNav;
