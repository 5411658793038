import PropTypes from "prop-types";
import { CardImg, Col, Row } from "reactstrap";
import Result from "../../shared/Result";

const TestStep = ({ step, seeScreenshot }) => {
  const name = `${step.command} [${step.target}]`;
  const notes = step.notesRaw.split("\n").map((note) => note.split(":"));

  let lastNote;
  if (notes.length > 1) {
    lastNote = notes[notes.length - 1];
  }

  return (
    <Row className="border-bottom py-2">
      <Col xs={1}>
        <span>{step.sequence + 1}.</span>
      </Col>
      <Col>
        <span className="fw-light">{name}</span>
        <Row>
          <Col xs={6} className="d-flex flex-column justify-content-around">
            <span className="text-danger">{step?.error}</span>
            {lastNote && lastNote[0] && (
              <div>
                <span>{lastNote[0]}:</span>
                <br />
                <span>{lastNote[1]}</span>
              </div>
            )}
            <Result isPassing={step.passing} />
          </Col>
          <Col>
            {step.screenshot?.url && (
              <CardImg
                src={step.screenshot.url}
                alt="screenshot"
                onClick={() => seeScreenshot(step.screenshot.url)}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

TestStep.propTypes = {
  step: PropTypes.shape({
    sequence: PropTypes.number,
    command: PropTypes.string,
    target: PropTypes.string,
    passing: PropTypes.bool,
    notesRaw: PropTypes.string,
    screenshot: PropTypes.shape({
      url: PropTypes.string,
    }),
    error: PropTypes.string,
  }).isRequired,
  seeScreenshot: PropTypes.func.isRequired,
};

export default TestStep;
