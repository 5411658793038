import { gql, useMutation, useQuery } from "@apollo/client";
import compact from "lodash";
import orderBy from "lodash/orderBy";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardTitle,
  UncontrolledAccordion,
} from "reactstrap";
import { displayDateTime } from "../../../util/dateUtils";
import { sortReleases } from "../../../util/releaseUtils";
import { IconSpacer } from "../../shared/Icon";
import { TooltipButton } from "../../shared/KvButton";
import KvInput from "../../shared/KvInput";
import PendingIcon from "../../shared/PendingIcon";
import ResultIcon from "../../shared/ResultIcon";
import "./FolderReport.scss";
import SuiteResultsTable from "./SuiteResultsTable";

const FOLDER_RESULTS = gql`
  query FolderWithResults($folderId: ID!) {
    getFolder(id: $folderId) {
      name
      testType
      testSuites {
        id
        name
        suiteResults {
          id
          testSuiteId
          countFailing
          countPassing
          countUnknown
          executionStartedAt
          executionFinishedAt
          name
          passing
          validationDocument {
            id
            url
            key
          }
          release {
            id
            name
          }
        }
      }
    }
  }
`;

const GET_ALL_RELEASE_NAMES = gql`
  query getReleaseNames {
    getAllReleases {
      id
      name
    }
  }
`;

const ADD_RESULTS_TO_RELEASE = gql`
  mutation AddSuiteResults($addSuiteResultInput: AddSuiteResultInput!) {
    addSuiteResults(addSuiteResultInput: $addSuiteResultInput) {
      id
      name
      suiteResults {
        id
        name
        passing
      }
    }
  }
`;

const UPDATE_FOLDER = gql`
  mutation UpdateFolder($id: ID!, $folder: FolderInput!) {
    updateFolder(id: $id, folder: $folder) {
      id
      testType
    }
  }
`;

const FolderReport = ({ folderId }) => {
  const [selectedSuiteResults, setSelectedSuiteResults] = useState([]);
  const [releaseId, setReleaseId] = useState();
  const { data, refetch } = useQuery(FOLDER_RESULTS, {
    variables: { folderId },
    skip: !folderId,
  });
  const { data: releaseData } = useQuery(GET_ALL_RELEASE_NAMES);
  const [addResultsToRelease, { loading: addResultsLoading }] = useMutation(
    ADD_RESULTS_TO_RELEASE,
    {
      refetchQueries: [FOLDER_RESULTS],
    }
  );
  const [updateFolder] = useMutation(UPDATE_FOLDER);

  const releases = sortReleases(releaseData?.getAllReleases);

  useEffect(() => {
    if (!releases.length) return;
    setReleaseId(releases[0].id);
  }, [releases]);

  const selectSuiteResult = (suiteResult) => {
    setSelectedSuiteResults((prev) => {
      if (prev.find((r) => r === suiteResult)) {
        return compact(prev.filter((r) => r !== suiteResult));
      }

      return [
        ...prev.map((r) =>
          r.testSuiteId === suiteResult.testSuiteId ? null : r
        ),
        suiteResult,
      ].filter((x) => x);
    });
  };

  const addResults = async () => {
    const suiteResultIds = selectedSuiteResults.map((r) => r.id);
    await addResultsToRelease({
      variables: {
        addSuiteResultInput: {
          releaseId,
          suiteResultIds,
        },
      },
    });
    setSelectedSuiteResults([]);
  };

  return (
    <div className="w-75">
      <Card className="border-0">
        <CardBody>
          <CardTitle>
            <h4>{data?.getFolder.name}</h4>
          </CardTitle>
          <div className="d-flex justify-content-between">
            <KvInput
              label="Test Type"
              name="testType"
              type="select"
              options={["-", "oq", "uat"]}
              value={data?.getFolder.testType || "-"}
              onChange={(e) =>
                updateFolder({
                  variables: {
                    id: folderId,
                    folder: {
                      testType: e.target.value === "-" ? null : e.target.value,
                    },
                  },
                })
              }
            />
            <div className="d-flex gap-3 align-items-center">
              <KvInput
                label="Release"
                type="select"
                name="release"
                onChange={(e) => setReleaseId(e.target.value)}
                value={releaseId}
              >
                {releases.map((release) => (
                  <option key={release.id} value={release.id}>
                    {release.name}
                  </option>
                ))}
              </KvInput>
              <TooltipButton
                isLoading={addResultsLoading}
                color="primary"
                onClick={addResults}
                disabled={!releaseId || !selectedSuiteResults.length}
                disabledReason="Select some test suites to add to the release"
                className="mt-3"
              >
                Add
              </TooltipButton>
            </div>
          </div>

          <UncontrolledAccordion>
            {orderBy(data?.getFolder?.testSuites, (t) => t.name).map(
              (suite, idx) => {
                const results = orderBy(
                  suite.suiteResults,
                  (result) => displayDateTime(result.executionStartedAt),
                  ["desc"]
                );

                let Icon;
                if (!results.length) Icon = <IconSpacer />;
                else if (results[0].passing == null) Icon = <PendingIcon />;
                else Icon = <ResultIcon isPassing={results[0].passing} />;

                return (
                  <AccordionItem key={suite.id}>
                    <AccordionHeader targetId={idx.toString()}>
                      <div className="">
                        {Icon}
                        <span className="ms-3">{suite.name}</span>
                      </div>
                    </AccordionHeader>
                    <AccordionBody accordionId={idx.toString()}>
                      <SuiteResultsTable
                        suiteResults={results}
                        refetch={refetch}
                        selectedSuiteResults={selectedSuiteResults}
                        selectSuiteResult={selectSuiteResult}
                      />
                    </AccordionBody>
                  </AccordionItem>
                );
              }
            )}
          </UncontrolledAccordion>
        </CardBody>
      </Card>
    </div>
  );
};

FolderReport.defaultProps = {
  folderId: undefined,
};

FolderReport.propTypes = {
  folderId: PropTypes.string,
};

export default FolderReport;
