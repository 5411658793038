import PropTypes from "prop-types";

const EmptyTable = ({ children, collection, className }) =>
  !collection || collection.length === 0 ? (
    <div className={`p-5 text-center fst-italic ${className}`}>{children}</div>
  ) : (
    ""
  );

EmptyTable.defaultProps = {
  collection: undefined,
  className: "",
};

EmptyTable.propTypes = {
  children: PropTypes.node.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */ // this type correct -ca
  collection: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
};

export default EmptyTable;
