import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { ME } from "../data/user";
import { useAuth } from "../Components/Auth/Auth0/Auth0Wrapper";

const Context = createContext();

const UserContextProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [user, setUser] = useState();
  const [getUser, { data }] = useLazyQuery(ME);

  useEffect(() => {
    if (isAuthenticated) getUser();
  }, [isAuthenticated]);

  useEffect(() => {
    if (data) {
      setUser(data.me);
    }
  }, [data]);

  const value = useMemo(() => ({ user }), [user]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUser = () => useContext(Context);
export default UserContextProvider;
