import { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Loading } from "../../shared";
import NoUser from "../NoUser";
import { useAuth } from "./Auth0Wrapper";
import { fetchLastLocation } from "./helpers";

const CallbackError = () => {
  const [params] = useSearchParams();

  const error = params.get("error");
  const errorDescription = params.get("error_description");

  if (errorDescription.match(/is not part of the org/)) {
    return (
      <NoUser>
        <p>
          You have signed in as a user that is not part of the specified
          organization.
        </p>
        <p>
          <Link to="/auth/reset" className="btn btn-primary">
            Sign in to a different organization
          </Link>
        </p>
      </NoUser>
    );
  }

  return (
    <NoUser
      message={
        <p>
          {error}: {errorDescription}
        </p>
      }
    />
  );
};

const Callback = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const error = params.get("error");

  useEffect(() => {
    // can't pass "async ()" to useEffect
    async function asyncWrapper() {
      if (isAuthenticated) {
        const location = fetchLastLocation() || "/";
        setTimeout(() => navigate(location), 500);
      }

      if (isAuthenticated === false && !error)
        setTimeout(() => navigate("/sign-in"), 500);
    }

    asyncWrapper();
  }, [isAuthenticated]);

  return error ? <CallbackError /> : <Loading full />;
};

export default Callback;
