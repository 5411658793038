import { createContext, useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";
import "./DragNDrop.scss";

const DragNDropContext = createContext();

const ContextProvider = ({ children }) => {
  const [active, setActive] = useState(false);
  const [activePayload, setActivePayload] = useState(null);

  const value = useMemo(
    () => ({
      active,
      setActive,
      activePayload,
      setActivePayload,
    }),
    [active, activePayload, setActivePayload]
  );

  return (
    <DragNDropContext.Provider value={value}>
      {children}
    </DragNDropContext.Provider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useDragNDrop = () => useContext(DragNDropContext);
export default ContextProvider;
