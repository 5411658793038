import { gql, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useState } from "react";
import { KvInput } from "../shared";
import KvModal from "../shared/KvModal";

const CREATE_TEST_PLAN = gql`
  mutation CreateTestPlan($name: String!) {
    createTestPlan(name: $name) {
      id
      name
    }
  }
`;

const CreateModal = ({ isOpen, onCancel }) => {
  const [name, setName] = useState();
  const [createPlan] = useMutation(CREATE_TEST_PLAN, {
    variables: { name },
    refetchQueries: ["GetAllTestPlans"],
  });

  const onConfirm = async () => {
    await createPlan();
    onCancel();
  };

  return (
    <KvModal
      isOpen={isOpen}
      header="Create Test Plan"
      onConfirm={onConfirm}
      onCancel={onCancel}
      disabled={!name}
    >
      <KvInput
        label="Name"
        name="name"
        onChange={(e) => setName(e.target.value)}
      />
    </KvModal>
  );
};

CreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateModal;
