import PropTypes from "prop-types";
import { Input } from "reactstrap";

const ToggleSwitch = ({ label, containerClassName, ...inputProps }) => {
  const { inputRef, ...rest } = inputProps;

  let contents = (
    <Input
      className="form-check-input cursor-pointer"
      type="checkbox"
      role="switch"
      ref={inputRef}
      {...rest}
    />
  );

  if (label)
    contents = (
      <label>
        {contents}
        {label}
      </label>
    );

  return (
    <div
      className={`form-check form-switch d-inline-block ${containerClassName}`}
    >
      {contents}
    </div>
  );
};

ToggleSwitch.defaultProps = {
  containerClassName: "",
  label: "",
};

ToggleSwitch.propTypes = {
  containerClassName: PropTypes.string,
  label: PropTypes.string,
};

export default ToggleSwitch;
