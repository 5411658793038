import { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Badge, Button } from "reactstrap";
import KVModal from "../shared/KvModal";
import { DELETE_SNAPSHOT, RESTORE_SNAPSHOT, UPDATE_SNAPSHOT } from "./data";
import { Icon, displayDateTime, pluralize } from "../shared";
import SingleEditableValue from "../shared/SingleEditableValue";

const displayRestores = (restores) => {
  if (!restores) return "";
  return `Restored ${pluralize(restores, "time")}`;
};

const displayRestoreStatus = (status) => {
  if (!status || status === "OK") return "";
  const color = status.match(/Started/) ? "secondary" : "danger";
  return (
    <p>
      <Badge color={color}>{status}</Badge>
    </p>
  );
};

const Snapshot = ({ snapshot }) => {
  const [modal, setModal] = useState();

  const [deleteSnapshot] = useMutation(DELETE_SNAPSHOT, {
    variables: { id: snapshot.id },
    refetchQueries: ["GetAllSnapshots"],
  });

  const [restoreSnapshot, { loading: restoreLoading }] = useMutation(
    RESTORE_SNAPSHOT,
    { variables: { id: snapshot.id } }
  );

  const [updateSnapshot] = useMutation(UPDATE_SNAPSHOT);

  const onDelete = async () => {
    await deleteSnapshot();
    setModal(undefined);
  };

  const onRestore = async () => {
    await restoreSnapshot();
    setModal(undefined);
  };

  const onUpdate = async (input) =>
    updateSnapshot({
      variables: { id: snapshot.id, input },
    });

  const name = snapshot.name || snapshot.createdAt;

  let key = snapshot.fileKey;
  if (key === "pending") key = <Badge color="secondary">Pending</Badge>;
  if (key === "error") key = <Badge color="warning">Error</Badge>;

  const canRestore = !["error", "pending"].includes(snapshot.fileKey);

  return (
    <>
      <tr>
        <td>
          <SingleEditableValue
            displayAs="div"
            value={name}
            onSubmit={(value) => onUpdate({ name: value })}
            name="snap name"
          />
          <div className="pt-2 text-gray">{key}</div>
        </td>
        <td>
          <SingleEditableValue
            displayAs="div"
            value={snapshot.description}
            onSubmit={(value) => onUpdate({ description: value })}
            name="snap description"
          />
        </td>
        <td>{displayDateTime(snapshot.createdAt)}</td>
        <td>
          {displayRestoreStatus(snapshot.restoreStatus)}
          {snapshot.lastRestoredAt && (
            <>
              {displayDateTime(snapshot.lastRestoredAt)}
              <br />
              {displayRestores(snapshot.restores)}
            </>
          )}
        </td>
        <td className="text-end">
          {canRestore && (
            <Button color="plain" onClick={() => setModal("restore")}>
              Restore <Icon icon="Sync" />
            </Button>
          )}
          <Button
            color="plain"
            onClick={() => setModal("delete")}
            className="ms-4"
          >
            <Icon icon="Delete" />
          </Button>
        </td>
      </tr>
      <KVModal
        isOpen={modal === "delete"}
        header={`Delete Snapshot ${name}`}
        onCancel={() => setModal(undefined)}
        onConfirm={onDelete}
        confirmColor="danger"
      >
        Are you sure you want to delete snapshot {name}?
      </KVModal>
      <KVModal
        isOpen={modal === "restore"}
        header={`Restore Snapshot ${name}`}
        onCancel={() => setModal(undefined)}
        onConfirm={onRestore}
        loading={restoreLoading}
      >
        <p>Are you sure you want to restore snapshot {name}?</p>
        <p>
          This will delete all changes made to the validation database since
          this snapshot was taken.
        </p>
      </KVModal>
    </>
  );
};

Snapshot.defaultProps = {};

Snapshot.propTypes = {
  snapshot: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    fileKey: PropTypes.string,
    description: PropTypes.string,
    lastRestoredAt: PropTypes.string,
    restoreStatus: PropTypes.string,
    restores: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
};

export default Snapshot;
