import PropTypes from "prop-types";
import DocumentGroup from "./DocumentGroup";

const ReleaseDocuments = ({ releaseId, documents }) => (
  <div className="d-flex gap-5">
    <DocumentGroup
      name="Change Control"
      types={["changeControl", "requirements", "traceabilityMatrix"]}
      docs={documents}
      releaseId={releaseId}
    />
    <DocumentGroup
      name="OQ"
      types={["oq"]}
      docs={documents}
      releaseId={releaseId}
    />
    <DocumentGroup
      name="UAT"
      types={["uat"]}
      docs={documents}
      releaseId={releaseId}
    />
  </div>
);

ReleaseDocuments.defaultProps = {
  documents: [],
};

ReleaseDocuments.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  releaseId: PropTypes.string.isRequired,
};

export default ReleaseDocuments;
