import { gql, useLazyQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import { Icon, Loading } from "../shared";
import KvButton from "../shared/KvButton";
import KvModal from "../shared/KvModal";
import SingleEditableValue from "../shared/SingleEditableValue";
import Issues from "./Issues";
import ReleaseDetails from "./ReleaseDetails";
import ReleaseDocuments from "./ReleaseDocuments";
import SuiteResults from "./SuiteResults";

const GET_RELEASE = gql`
  query GetRelease($id: ID!) {
    getRelease(id: $id) {
      id
      documents {
        id
        file {
          id
          key
          bucket
          url
        }
        type
      }
      issues {
        id
        changeRisk
        iconUrl
        key
        summary
        type
        url
        subsystem
        addedToReleaseAt
      }
      suiteResults {
        id
        name
        passing
        testSuite {
          id
          folder {
            id
            ghostId
            testType
          }
        }
      }
    }
  }
`;

const UPDATE_RELEASE = gql`
  mutation UpdateRelease($id: ID!, $input: ReleaseCreateInput!) {
    updateRelease(id: $id, input: $input) {
      id
      releaseDate
      name
    }
  }
`;

const DELETE_RELEASE = gql`
  mutation DeleteRelease($id: ID!) {
    deleteRelease(id: $id) {
      id
    }
  }
`;

const Release = ({ release, defaultExpanded }) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [showModal, setShowModal] = useState(false);

  const [getReleaseDetails, { data, loading }] = useLazyQuery(GET_RELEASE, {
    variables: { id: release.id },
  });
  const [deleteRelease] = useMutation(DELETE_RELEASE, {
    variables: { id: release.id },
    refetchQueries: ["GetAllReleases"],
  });

  useEffect(() => {
    if (expanded) {
      getReleaseDetails();
    }
  }, [expanded]);

  const [updateRelease] = useMutation(UPDATE_RELEASE);

  const onUpdate = async (input) => {
    await updateRelease({
      variables: {
        id: release.id,
        input,
      },
    });
  };

  const onDelete = async () => {
    await deleteRelease();
    setShowModal(false);
  };

  const toggleExapnded = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };

  const fullRelease = {
    ...release,
    ...data?.getRelease,
  };

  return (
    <>
      <div className="d-flex">
        <KvButton color="plain" onClick={toggleExapnded}>
          <div className="d-flex align-items-center gap-3">
            <Icon icon={expanded ? "Collapse" : "Expand"} className="ms-3" />
            <SingleEditableValue
              value={release.name}
              onSubmit={(name) => onUpdate({ name })}
              name="releaseName"
              displayAs="h2"
            />
          </div>
        </KvButton>
        <KvButton
          id={`delete-${release.id}`}
          className="ms-auto"
          color="plain"
          onClick={() => setShowModal(true)}
        >
          <Icon icon="Delete" fixed />
        </KvButton>
      </div>
      {loading && <Loading />}
      {expanded && data && (
        <Card body className="my-3">
          <Container className="mx-0">
            <Row>
              <Col xs={7}>
                <ReleaseDetails release={fullRelease} onUpdate={onUpdate} />
              </Col>
              <Col>
                <ReleaseDocuments
                  releaseId={fullRelease.id}
                  documents={fullRelease.documents}
                />
              </Col>
            </Row>
          </Container>

          <Container className="mx-0 mt-3">
            <Row>
              <Col xs={7} className="pe-5">
                <Issues
                  releaseId={fullRelease.id}
                  issues={fullRelease.issues}
                />
              </Col>
              <Col className="mt-2">
                <SuiteResults
                  releaseId={fullRelease.id}
                  suiteResults={fullRelease.suiteResults}
                />
              </Col>
            </Row>
          </Container>
        </Card>
      )}
      <KvModal
        isOpen={showModal}
        header={`Delete Release ${release.name}`}
        onCancel={() => setShowModal(false)}
        onConfirm={onDelete}
        confirmColor="danger"
      >
        Are you sure you want to delete release {release.name}?
      </KvModal>
    </>
  );
};

Release.defaultProps = {
  defaultExpanded: false,
};

Release.propTypes = {
  defaultExpanded: PropTypes.bool,
  release: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    webCommitSha: PropTypes.string,
    apiCommitSha: PropTypes.string,
    webCompareLink: PropTypes.string,
    apiCompareLink: PropTypes.string,
    releaseDate: PropTypes.string,
    issues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        summary: PropTypes.string,
      })
    ),
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    suiteResults: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        passing: PropTypes.bool,
      })
    ),
  }).isRequired,
};

export default Release;
