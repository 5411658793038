import { useEffect, useState } from "react";
import { useMutation, gql, useQuery, useLazyQuery } from "@apollo/client";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import { Icon, Loading } from "../shared";
import KvButton from "../shared/KvButton";
import {
  GET_BACKGROUND_JOB,
  GET_BACKGROUND_JOBS,
} from "../shared/data/backgroundJobs";

const GENERATE_DOCUMENTS = gql`
  mutation CreateReleaseDocuments($input: CreateReleaseDocumentsInput!) {
    createReleaseDocuments(input: $input) {
      id
      state
      type
    }
  }
`;

const GET_RELEASE_DOCUMENTS = gql`
  query GetRelease($id: ID!) {
    getRelease(id: $id) {
      id
      documents {
        id
        file {
          id
          key
          bucket
          url
        }
        type
      }
    }
  }
`;

const getDocInfo = (type) =>
  ({
    changeControl: { name: "Change Control", icon: "ChangeControl" },
    requirements: { name: "Requirements", icon: "Requirements" },
    traceabilityMatrix: { name: "Traceability Matrix", icon: "Traceability" },
    oq: { name: "OQ Files", icon: "Test" },
    uat: { name: "UAT Files", icon: "UAT" },
  }[type]);

const DocumentGroup = ({ name, types, docs, releaseId }) => {
  const [activeJob, setActiveJob] = useState();
  const [generateDocs] = useMutation(GENERATE_DOCUMENTS, {
    variables: { input: { releaseId, types } },
  });
  const [getReleaseDocs] = useLazyQuery(GET_RELEASE_DOCUMENTS, {
    variables: { id: releaseId },
    fetchPolicy: "network-only",
  });

  const { data: jobPoll, stopPolling } = useQuery(GET_BACKGROUND_JOB, {
    variables: { id: activeJob?.id },
    skip: !activeJob?.id,
    pollInterval: 1500,
    fetchPolicy: "no-cache",
  });

  const { data: backroundJobData } = useQuery(GET_BACKGROUND_JOBS, {
    variables: { state: "processing" },
  });

  useEffect(() => {
    if (!backroundJobData) return;
    const generateJob = backroundJobData?.getAllBackgroundJobs.find(
      (job) =>
        job.type === "generateReleaseDocs" && isEqual(job.input.types, types)
    );
    if (generateJob) setActiveJob(generateJob);
  }, [backroundJobData]);

  useEffect(() => {
    if (!jobPoll) return;
    if (["finished", "failed"].includes(jobPoll.getBackgroundJob.state)) {
      stopPolling();
      setActiveJob();
      getReleaseDocs();
    }
  }, [jobPoll]);

  const createReleaseDocsJob = async () => {
    const job = await generateDocs();
    setActiveJob(job.data.createReleaseDocuments);
  };

  const hideButton = activeJob ? "invisible" : "";

  return (
    <div>
      <div className="d-flex border-right">
        <h5 className="mb-0 text-gray-medium">{name}</h5>
        {!activeJob && (
          <KvButton
            color="plain"
            className="ms-auto"
            onClick={createReleaseDocsJob}
          >
            <Icon icon="refresh" />
          </KvButton>
        )}
      </div>

      <div className="d-flex gap-3 p-3 position-relative">
        {!!activeJob && <Loading className="position-absolute start-50" />}
        {types.map((type) => {
          const info = getDocInfo(type);
          const doc = docs.find((d) => d.type === type);
          return (
            <div key={[doc?.id, type].join()}>
              <KvButton
                color="plain"
                disabled={!doc?.file?.url}
                onClick={() => window.open(doc?.file?.url, "_blank")}
              >
                <Icon icon={info.icon} size={3} className={hideButton} />
                <div className={`mt-2 ${hideButton}`}>{info.name}</div>
              </KvButton>
            </div>
          );
        })}
      </div>
    </div>
  );
};

DocumentGroup.defaultProps = {
  name: "",
  docs: {},
};

DocumentGroup.propTypes = {
  name: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    })
  ),
  releaseId: PropTypes.string.isRequired,
};

export default DocumentGroup;
