import PropTypes from "prop-types";
import { Table } from "reactstrap";
import EmptyTable from "../EmptyTable";
import SortableTable from "./SortableTable";
import SortableTableContents from "./SortableTableContents";
import TableBody from "./TableBody";
import TableHeader from "./tableHelpers";
import { tableDefaultProps, tableShape } from "./tableShapes";

/* Wrapper for a table that removes some boilerplate.
 *  headers:
 *    Array of objects with a display name name and key.
 *    Columns will appear in the order given.
 *  entries:
 *    Array of objects with keys that match the keys in the headers.
 * See shape objects for full list of properties
 */
const NonSortableTable = ({
  headers,
  entries,
  onRowClick,
  draggable,
  rowHighlightFn,
  additionalRows,
  footer,
  showRowNumbers,
  ...rest
}) => (
  <Table {...rest}>
    <TableHeader headers={headers} showRowNumbers={showRowNumbers} />
    <TableBody
      headers={headers}
      entries={entries}
      onRowClick={onRowClick}
      rowHighlightFn={rowHighlightFn}
      draggable={draggable}
      additionalRows={additionalRows}
      showRowNumbers={showRowNumbers}
    />
    {footer}
  </Table>
);

NonSortableTable.defaultProps = tableDefaultProps;
NonSortableTable.propTypes = tableShape;

const SimpleTable = ({
  sortable,
  headers,
  entries,
  onRowClick,
  rowHighlightFn,
  draggable,
  additionalRows,
  footer,
  emptyMessage,
  showRowNumbers,
  ...rest
}) => (
  <>
    {sortable ? (
      <SortableTable {...rest}>
        <SortableTableContents
          headers={headers}
          entries={entries}
          onRowClick={onRowClick}
          draggable={draggable}
          rowHighlightFn={rowHighlightFn}
          additionalRows={additionalRows}
          footer={footer}
          showRowNumbers={showRowNumbers}
        />
      </SortableTable>
    ) : (
      <NonSortableTable
        headers={headers}
        entries={entries}
        onRowClick={onRowClick}
        draggable={draggable}
        rowHighlightFn={rowHighlightFn}
        additionalRows={additionalRows}
        showRowNumbers={showRowNumbers}
        {...rest}
      />
    )}
    {emptyMessage && (
      <EmptyTable collection={entries}>{emptyMessage}</EmptyTable>
    )}
  </>
);

SimpleTable.defaultProps = {
  ...tableDefaultProps,
  emptyMessage: "",
  sortable: false,
};

SimpleTable.propTypes = {
  ...tableShape,
  emptyMessage: PropTypes.node,
  sortable: PropTypes.bool,
};

export default SimpleTable;
