import _ from "lodash";

const suiteNameToNode = (name) => {
  let converted = name.replace(/\(|\)/g, "");
  converted = `execute-${converted}`;
  return _.kebabCase(converted.toLowerCase());
};

const suiteYaml = (suite, previous, startUrl) => `${suiteNameToNode(
  suite.name
)}:
  needs: ${previous ? suiteNameToNode(previous.name) : "start"}
  name: ${suite.name}
  uses: ./.github/workflows/ghostinspector.yml
  secrets:
    GI_API_KEY: \${{ secrets.GI_API_KEY }}
  with:
    suiteId: ${suite.ghostId}
    ${startUrl ? `startUrl: ${startUrl}\n` : ""}
`;

export const convertSequenceStepsToYaml = (steps, startUrl) => {
  let yaml = "";
  steps.forEach((step, idx) => {
    const suite = step.testSuite;
    if (suite) yaml += suiteYaml(suite, steps[idx - 1]?.testSuite, startUrl);
  });
  return yaml;
};

export default convertSequenceStepsToYaml;
