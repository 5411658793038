import sendErrorToRaygun from "./sendErrorToRaygun";

export const jsonSafeParse = (jsonStr, fallback) => {
  if (!jsonStr) return fallback;

  try {
    return JSON.parse(jsonStr);
  } catch (e) {
    sendErrorToRaygun(e);
    return fallback;
  }
};

export default { jsonSafeParse };
