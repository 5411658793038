export default function sendErrorToRaygun(error) {
  if (!window.rg4js) {
    return;
  }
  try {
    window.rg4js("send", error);
  } catch (e) {
    console.error("Raygun tracking error", e);
  }
}
