import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import SimpleAlert from "../shared/SimpleAlert";
import KvModal from "../shared/KvModal";
import KvInput from "../shared/KvInput";
import { CREATE_MANAGED_RUN } from "./data";
import FolderSelect from "./FolderSelect";
import { managedRunShape } from "../shared/shapes";

// Source run for duplication path
const CreateManagedRun = ({ closeModal, isOpen, sourceRun }) => {
  const [attrs, setAttrs] = useState({
    name: sourceRun ? `${sourceRun.name} copy` : "",
  });
  const [alert, setAlert] = useState();
  const [createManagedRun, { data, loading, error }] =
    useMutation(CREATE_MANAGED_RUN);

  const onSubmit = () => {
    let newAttrs = { ...attrs };

    if (sourceRun) {
      newAttrs = {
        ...newAttrs,
        testIds: sourceRun.testIds,
        suiteIds: sourceRun.suiteIds,
        folderId: sourceRun.folder.id,
      };
    }

    createManagedRun({
      variables: { managedRun: newAttrs },
      refetchQueries: ["GetAllManagedRuns"],
    });
  };

  useEffect(() => {
    if (data) {
      setAlert(["success", "Managed run created"]);
      setTimeout(() => {
        closeModal();
        setAlert(undefined);
      }, 1500);
    }
  }, [data]);

  useEffect(() => {
    if (error) setAlert(["danger", error.message]);
  }, [error]);

  const onChange = ({ target }) =>
    setAttrs((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));

  return (
    <KvModal
      loading={loading}
      onCancel={closeModal}
      onConfirm={onSubmit}
      header={`New Managed Run ${sourceRun && `from ${sourceRun.name}`}`}
      isOpen={isOpen}
      confirm="Create"
    >
      <SimpleAlert alert={alert} />

      <KvInput
        type="text"
        name="name"
        label="Name"
        onChange={onChange}
        defaultValue={attrs.name}
      />
      {!sourceRun && (
        <FolderSelect label="Test Folder" name="folderId" onChange={onChange} />
      )}
    </KvModal>
  );
};

CreateManagedRun.defaultProps = { sourceRun: undefined };
CreateManagedRun.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  sourceRun: PropTypes.shape(managedRunShape),
};

export default CreateManagedRun;
