import { gql, useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { UncontrolledTooltip } from "reactstrap";
import KvButton from "../../shared/KvButton";
import { GET_BACKGROUND_JOB } from "../../shared/data/backgroundJobs";

const CREATE_VALIDATION_DOC = gql`
  mutation CreateValidationDocument($input: CreateValidationDocumentInput!) {
    createValidationDocument(input: $input) {
      id
      state
    }
  }
`;

const ValidationDocument = ({ doc, suiteResultId, refetchResult }) => {
  const [createDoc, { data: jobData, loading }] = useMutation(
    CREATE_VALIDATION_DOC,
    {
      variables: {
        input: { suiteResultId },
      },
    }
  );
  const { data: jobPoll, stopPolling } = useQuery(GET_BACKGROUND_JOB, {
    variables: { id: jobData?.createValidationDocument?.id },
    skip: !jobData?.createValidationDocument?.id,
    pollInterval: 1500,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!jobPoll) return;
    if (jobPoll.getBackgroundJob.state === "finished") {
      stopPolling();
      refetchResult();
    }
  }, [jobPoll]);

  const isLoading =
    loading || (jobPoll && jobPoll?.getBackgroundJob.state !== "finished");

  const downloadDoc = () => {
    window.open(doc?.url, "_blank");
  };

  return (
    <div className={`d-flex justify-content-${doc?.url ? "around" : "center"}`}>
      {doc?.url && (
        <>
          <KvButton
            color="plain"
            disabled={!doc?.url || isLoading}
            onClick={downloadDoc}
            icon="Word"
            id={`validation-doc-${suiteResultId}`}
          />
          <UncontrolledTooltip
            target={`validation-doc-${suiteResultId}`}
            placement="top-start"
          >
            Download validation doc
          </UncontrolledTooltip>
        </>
      )}

      <KvButton
        color="plain"
        isLoading={!!isLoading}
        onClick={createDoc}
        icon="Refresh"
        id={`recreate-doc-${suiteResultId}`}
      />
      <UncontrolledTooltip
        target={`recreate-doc-${suiteResultId}`}
        placement="top-start"
      >
        Recreate validation doc
      </UncontrolledTooltip>
    </div>
  );
};

ValidationDocument.defaultProps = {
  doc: undefined,
};

ValidationDocument.propTypes = {
  doc: PropTypes.shape({
    url: PropTypes.string,
  }),
  suiteResultId: PropTypes.string.isRequired,
  refetchResult: PropTypes.func.isRequired,
};

export default ValidationDocument;
