import { Route, Routes as RouterRoutes } from "react-router-dom";
import { AuthReset, Callback, SignOut } from "./Components/Auth";
import NoUser from "./Components/Auth/NoUser";
import SignIn from "./Components/Auth/SignIn";
import Looker from "./Components/Looker";
import ManagedRuns from "./Components/ManagedRuns";
import NotFound from "./Components/NotFound";
import Releases from "./Components/Releases";
import Snapshots from "./Components/Snapshots";
import Status from "./Components/Status";
import EditTestPlan from "./Components/TestRunner/EditTestPlan";
import TestRunner from "./Components/TestRunner/TestRunner";
import Tests from "./Components/Tests";

const Routes = () => (
  <RouterRoutes>
    <Route path="/sign-out" element={<SignOut />} />
    <Route path="/no-user" element={<NoUser />} />
    <Route path="/sign-in" element={<SignIn />} />
    <Route path="/status" element={<Status />} />
    <Route path="/auth/callback/*" element={<Callback />} />
    <Route path="/auth/reset" element={<AuthReset />} />

    <Route path="/releases" element={<Releases />} />
    <Route path="/runs" element={<ManagedRuns />} />
    <Route path="/looker" element={<Looker />} />
    <Route path="/snapshots" element={<Snapshots />} />
    <Route path="/test-runner" element={<TestRunner />} />
    <Route
      path="/test-runner/test-plan/:testPlanId"
      element={<EditTestPlan />}
    />
    <Route path="/" element={<Tests />} />

    <Route path="*" element={<NotFound />} />
  </RouterRoutes>
);

export default Routes;
