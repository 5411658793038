import { useEffect } from "react";

const targetIsIgnorable = (target, ref) => {
  const selectors = [
    ".modal-dialog", // modal
    ".kv-toast-container",
  ];

  if (target.closest(selectors.join(", "))) return true;

  const offcanvas = ".offcanvas-body";

  const clickOffcanvas = target.closest(offcanvas);
  const refOffcanvas = ref?.closest(offcanvas);
  // allow within the same offcanvas (note: KV-2049)
  if (clickOffcanvas && clickOffcanvas === refOffcanvas) return true;

  return false;
};

const useClickOutside = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = ref.current && !ref.current.contains(event.target);
      if (isOutside && !targetIsIgnorable(event.target, ref.current)) {
        if (callback) callback();

        // this helps avoid some stale context issues with callback
        if (ref.onClickOutside) ref.onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export default useClickOutside;
