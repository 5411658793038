import PropTypes from "prop-types";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Icon } from "../Components/shared";
import { useUser } from "./UserContextProvider";

const ToolsMenu = ({ children }) => {
  const { user } = useUser();
  const [open, setOpen] = useState(false);

  if (!user) return "";

  return (
    <Dropdown
      isOpen={open}
      toggle={() => setOpen(!open)}
      className="d-inline-block"
    >
      <DropdownToggle
        color="plain"
        className="text-nowrap d-flex align-items-center"
      >
        <span className="text-nowrap text-end text-gray-medium">
          <span className="fw-bolder">{user.name}</span>
        </span>
        <Icon icon="ChevronDown" className="ms-3" />
      </DropdownToggle>
      <DropdownMenu>{children}</DropdownMenu>
    </Dropdown>
  );
};

ToolsMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

const HeaderTools = () => (
  <div className="kv-header-tools d-flex align-items-center ms-auto">
    <ToolsMenu icon="User">
      <Link
        to="/sign-out"
        tabIndex="0"
        role="menuitem"
        className="dropdown-item"
      >
        Sign out
      </Link>
    </ToolsMenu>
  </div>
);
export default HeaderTools;
