import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";
import { useAtom } from "jotai";
import { gql, useQuery } from "@apollo/client";
import { Spinner, Table } from "reactstrap";
import Result from "../../shared/Result";
import { sidePanelResultAtom } from "../atoms";

export const TestResultFields = gql`
  fragment TestResultFields on TestResult {
    id
    name
    passing
    executionTime
    executionStartedAt
    executionFinishedAt
    screenshot {
      id
      url
      key
    }
  }
`;
export const SuiteResultFields = gql`
  fragment SuiteResultFields on SuiteResult {
    id
    name
    passing
    countFailing
    countPassing
    countUnknown
    executionStartedAt
    executionFinishedAt
    validationDocument {
      id
      url
      key
    }
  }
`;

const GET_SUITE_RESULT = gql`
  query GetSuiteResult($id: ID!) {
    getSuiteResult(id: $id) {
      ...SuiteResultFields
      testResults {
        ...TestResultFields
      }
    }
  }
  ${SuiteResultFields}
  ${TestResultFields}
`;

const ResultTable = ({ suiteResultId }) => {
  const [selectedTestRunId, setSelectedTestRunId] =
    useAtom(sidePanelResultAtom);
  const { data, loading } = useQuery(GET_SUITE_RESULT, {
    variables: {
      id: suiteResultId,
    },
  });

  const sortedTestResults = orderBy(data?.getSuiteResult?.testResults, "executionStartedAt");

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <Table borderless size="sm">
      <thead>
        <tr>
          <th>Test</th>
          <th className="text-center" align="center">
            Result
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedTestResults.map((result) => (
          <tr
            key={result.id}
            className={`kv-clickable-row ${
              result.id === selectedTestRunId ? "kv-selected" : ""
            }`}
            onClick={() => setSelectedTestRunId(result.id)}
          >
            <td>{result.name}</td>
            <td align="center">
              <Result isPassing={result.passing} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

ResultTable.propTypes = {
  suiteResultId: PropTypes.string.isRequired,
};

export default ResultTable;
