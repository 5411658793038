import orderBy from "lodash/orderBy";
import { useSortableTable } from "./SortableTable";
import TableBody from "./TableBody";
import TableHeader from "./tableHelpers";
import { tableDefaultProps, tableShape } from "./tableShapes";

const SortableTableContents = ({
  headers,
  entries,
  onRowClick,
  draggable,
  rowHighlightFn,
  additionalRows,
  footer,
  showRowNumbers,
}) => {
  const { sortKey, reverse } = useSortableTable();
  const sortKeyHeaderEntry = headers.find((h) => h.key === sortKey);

  const sortedEntries = sortKeyHeaderEntry?.sortFn
    ? sortKeyHeaderEntry?.sortFn(entries)
    : orderBy(entries, [sortKey, "name", "id"]); // fallback to name and id to avoid unexpected re-ordering within the primary sort key

  if (reverse) sortedEntries.reverse();

  return (
    <>
      <TableHeader headers={headers} showRowNumbers={showRowNumbers} sortable />
      <TableBody
        headers={headers}
        entries={sortedEntries}
        onRowClick={onRowClick}
        draggable={draggable}
        rowHighlightFn={rowHighlightFn}
        additionalRows={additionalRows}
        showRowNumbers={showRowNumbers}
      />
      {footer}
    </>
  );
};

SortableTableContents.defaultProps = tableDefaultProps;
SortableTableContents.propTypes = tableShape;

export default SortableTableContents;
