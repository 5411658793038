import { gql, useMutation } from "@apollo/client";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Card } from "reactstrap";
import EmptyTable from "../shared/EmptyTable";
import KvButton from "../shared/KvButton";
import { testSuiteShape } from "../shared/shapes";

const UPDATE_SEQUENCE_STEP = gql`
  mutation UpdateSequenceStep($id: String!, $sequenceStep: SequenceStepInput!) {
    updateTestSequenceStep(id: $id, sequenceStep: $sequenceStep) {
      id
      stepPosition
    }
  }
`;

// the react-beautiful-dnd library doesn't work ootb with StrictMode on
export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

StrictModeDroppable.defaultProps = {
  children: null,
};

StrictModeDroppable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

const TestSequence = ({ sequenceIndex, steps, onAddTests }) => {
  const [updateSequenceStep] = useMutation(UPDATE_SEQUENCE_STEP);

  const orderedSteps = _.orderBy(steps, "stepPosition");

  const onDragEnd = async (result) => {
    const filteredSteps = orderedSteps.filter(
      (s) => s.id !== result.draggableId
    );
    const droppedEntry = filteredSteps[result.destination.index];
    const droppedPos = droppedEntry?.stepPosition;
    const droppedId = droppedEntry?.id;

    if (droppedId === result.draggableId) return;

    const prevPos = filteredSteps[result.destination.index - 1]?.stepPosition;

    let newPos;
    if (result.destination.index === 0)
      newPos = filteredSteps[0].stepPosition - 100;
    else if (result.destination.index === filteredSteps.length)
      newPos = prevPos + 100;
    else newPos = (droppedPos + prevPos) / 2;

    await updateSequenceStep({
      variables: {
        id: result.draggableId,
        sequenceStep: {
          stepPosition: newPos,
        },
      },
      optimisticResponse: {
        updateTestSequenceStep: {
          __typename: "SequenceStep",
          id: result.draggableId,
          stepPosition: newPos,
        },
      },
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h5>Sequence {sequenceIndex + 1}</h5>
        <KvButton icon="Plus" color="plain-blue" onClick={onAddTests}>
          Add Tests
        </KvButton>
      </div>
      <EmptyTable collection={steps}>
        No tests have been added to this sequence yet
      </EmptyTable>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="test-sequence">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="p-3 d-flex flex-column gap-1"
            >
              {orderedSteps.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(p, s) => (
                    <div
                      ref={p.innerRef}
                      {...p.draggableProps}
                      {...p.dragHandleProps}
                      className="user-select-none"
                      style={p.draggableProps.style}
                    >
                      <Card body>
                        {item.testSuite.name}
                        {item.testSuite?.folder?.name}
                      </Card>
                      {!s.isDragging && index !== steps.length - 1 && (
                        <div className="text-center mt-1">↓</div>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
};

TestSequence.propTypes = {
  sequenceIndex: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape(testSuiteShape)).isRequired,
  onAddTests: PropTypes.func.isRequired,
};

export default TestSequence;
