import { gql, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

const SYNC_FOLDER = gql`
  mutation CreateFolderSyncJob($input: GhostInspectorSyncInput!) {
    createFolderSyncJob(input: $input) {
      id
      state
      type
      createdAt
    }
  }
`;

const GET_GHOST_FOLDERS = gql`
  query GetGhostInspectorFolders {
    getGhostInspectorFolders {
      _id
      name
    }
  }
`;

const SyncFolder = () => {
  const [folderId, setFolderId] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [syncFolder] = useMutation(SYNC_FOLDER);
  const { data } = useQuery(GET_GHOST_FOLDERS);

  const [startDate, endDate] = dateRange;

  const sync = () => {
    syncFolder({
      variables: {
        input: {
          folderId,
          startDate,
          endDate,
        },
      },
    });
  };

  return (
    <div className="mt-5 ps-4">
      <h4>Folder Sync</h4>
      <Form>
        <Row className="row-cols-lg-auto colg-2">
          <Col>
            <FormGroup>
              <Label for="folder-select">Ghost Inspector Folder</Label>
              <Input
                type="select"
                name="folder-select"
                id="folder-select"
                value={folderId}
                onChange={(e) => setFolderId(e.target.value)}
              >
                {data?.getGhostInspectorFolders.map((f) => (
                  <option key={f._id} value={f._id}>
                    f.name
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Date Range</Label>
              <ReactDatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={(range) => setDateRange(range)}
                customInput={<Input />}
              />
            </FormGroup>
          </Col>
          <Col className="align-self-center mt-3">
            <Button
              onClick={sync}
              disabled={!folderId}
              color="primary"
              className="ms-2"
            >
              Sync
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SyncFolder;
