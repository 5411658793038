import PropTypes from "prop-types";
import { useRef } from "react";
import { Offcanvas, OffcanvasBody } from "reactstrap";
import "./Drawer.scss";
import useClickOutside from "./hooks/useClickOutside";

const Drawer = ({
  children,
  isIn,
  className,
  bodyClassName,
  innerClassName,
  onClickOutside,
  ...rest
}) => {
  const wholeClass = `kv-drawer bg-light ms-auto ${className}`;
  const ref = useRef(null);
  useClickOutside(ref, onClickOutside);

  return (
    <Offcanvas
      isOpen={isIn}
      backdrop={false}
      direction="end"
      className={wholeClass}
      {...rest}
    >
      <OffcanvasBody className={bodyClassName}>
        <div ref={ref} className="h-100">
          {isIn ? <div className={innerClassName}>{children}</div> : <span />}
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
};

Drawer.defaultProps = {
  isIn: true,
  className: "",
  bodyClassName: "",
  innerClassName: "kv-drawer__inner",
  onClickOutside: () => {},
};

Drawer.propTypes = {
  isIn: PropTypes.bool,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  innerClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClickOutside: PropTypes.func,
};

export default Drawer;
