import PropTypes from "prop-types";
import MainNav from "./MainNav";
import TopTools from "./TopTools";
import "./Layout.scss";

const Layout = ({ children }) => (
  <div className="kv-app d-flex">
    <MainNav />
    <div className="w-100 d-flex flex-column">
      <TopTools />
      <div className="h-100">{children}</div>
    </div>
  </div>
);
Layout.propTypes = { children: PropTypes.node.isRequired };

export default Layout;
