import { useQuery } from "@apollo/client";
import { useState } from "react";
import { Button, Table } from "reactstrap";
import NewSnapshotForm from "./New";
import Snapshot from "./Snapshot";
import { Icon } from "../shared";
import { GET_SNAPSHOTS } from "./data";

const Snapshots = () => {
  const [creating, setCreating] = useState();
  const { data } = useQuery(GET_SNAPSHOTS);

  return (
    <div className="kv-main--wrapper__scrollable">
      <div className="kv-main container-fluid kv-home pb-4" role="main">
        <div className="d-flex">
          <h3>Snapshots</h3>
          {!creating && (
            <Button
              className="ms-auto"
              color="primary"
              onClick={() => setCreating(true)}
            >
              Take Snapshot <Icon icon="camera" solid className="ps-1" />
            </Button>
          )}
        </div>
        {creating && <NewSnapshotForm onComplete={() => setCreating(false)} />}

        <Table className="mt-4">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Created</th>
              <th>Restored</th>
              <td />
            </tr>
          </thead>
          <tbody>
            {data?.getAllSnapshots.map((s) => (
              <Snapshot key={s.id} snapshot={s} />
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Snapshots;
