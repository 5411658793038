import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from "reactstrap";
import { SYNC_LOOKER_DASHBOARDS } from "./data";
import KvButton from "../shared/KvButton";
import RenderWhenVisible from "../shared/RenderWhenVisible";
import Dashboards from "./Dashboards";
import ContextProvider, { useLookerSync } from "./ContextProvider";
import DashboardReport from "./DashboardReport";
import BoardReport from "./BoardReport";

const Sync = ({ targetEnv }) => {
  const { sourceEnv, selectedDashboardIds } = useLookerSync();
  const [doSync, { loading }] = useMutation(SYNC_LOOKER_DASHBOARDS);
  const onClick = async () =>
    doSync({
      variables: { targetEnv, sourceEnv, dashboardIds: selectedDashboardIds },
    });

  return (
    <KvButton
      color={targetEnv === "production" ? "danger" : "primary"}
      className="ms-3"
      onClick={onClick}
      isLoading={loading}
    >
      Sync to {_.startCase(targetEnv)}
    </KvButton>
  );
};

Sync.propTypes = { targetEnv: PropTypes.string.isRequired };

const Looker = () => (
  <ContextProvider>
    <div className="kv-main--wrapper__scrollable">
      <div className="kv-main pb-4">
        <Dashboards />

        <div>
          <div className="d-flex justify-content-end">
            <Sync targetEnv="development" />
            <Sync targetEnv="demo" />
            <Sync targetEnv="sandbox" />
            <Sync targetEnv="validation" />
            <Sync targetEnv="production" />
          </div>
          <p className="w-50 py-2 ms-auto">
            Note: if you sync to production, the report will be placed in the
            “Public” folder. Otherwise, it will be in the environment folder
            under Public, e.g.: “Public/Demo.
          </p>
        </div>

        <UncontrolledAccordion className="my-4" defaultOpen={[]}>
          <AccordionItem>
            <AccordionHeader targetId="dashboards">
              Dashboard Database Environment Report
            </AccordionHeader>
            <AccordionBody accordionId="dashboards">
              <RenderWhenVisible>
                <DashboardReport />
              </RenderWhenVisible>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="boards">
              Board Database Environment Report
            </AccordionHeader>
            <AccordionBody accordionId="boards">
              <RenderWhenVisible>
                <BoardReport />
              </RenderWhenVisible>
            </AccordionBody>
          </AccordionItem>
        </UncontrolledAccordion>
      </div>
    </div>
  </ContextProvider>
);

export default Looker;
