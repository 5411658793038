import { gql, useMutation } from "@apollo/client";
import KvButton from "./KvButton";

const SYNC_GHOST_DEFINITIONS = gql`
  mutation SyncGhostDefinitions {
    syncGhostInspectorDefinitions
  }
`;

const SyncGhostDefinitionsButton = ({ ...buttonAttrs }) => {
  const [syncDefinitions, { loading }] = useMutation(SYNC_GHOST_DEFINITIONS, {
    refetchQueries: ["GetFolders"],
  });

  return (
    <KvButton
      color="primary"
      onClick={syncDefinitions}
      disabled={loading}
      isLoading={loading}
      {...buttonAttrs}
    >
      Resync
    </KvButton>
  );
};

export default SyncGhostDefinitionsButton;
