import { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const GET_HEALTH = gql`
  query GetHealth {
    getHealth {
      time
      dbTime
    }
  }
`;

const Status = () => {
  const { data } = useQuery(GET_HEALTH, {
    context: {
      headers: { Authorization: "Bearer public-action" },
    },
  });
  const [health, setHealth] = useState();
  useEffect(() => setHealth(data?.getHealth), [data]);
  return health ? (
    <div className="kv-main">status ok: {health.dbTime}</div>
  ) : (
    ""
  );
};

export default Status;
