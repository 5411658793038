import propTypes from "prop-types";
import { jsonSafeParse } from "../../../util/jsonHelpers";
import { useDragNDrop } from "./ContextProvider";

const Droppable = ({
  children,
  onDrop,
  className,
  activeClassName,
  ...rest
}) => {
  const { active, setActive } = useDragNDrop();

  const wrappedOnDrop = (payload) => {
    onDrop(jsonSafeParse(payload, payload));
    setActive(false);
  };

  return (
    <div
      role="presentation"
      className={`${className} kv-drag-n-drop__droppable ${
        active ? activeClassName : ""
      }`}
      onDrop={(e) => {
        e.preventDefault();
        wrappedOnDrop(e.dataTransfer.getData("text"));
        e.stopPropagation();
      }}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={(e) => e.preventDefault()}
      {...rest}
    >
      {children}
    </div>
  );
};

Droppable.defaultProps = {
  className: "kv-droppable",
  activeClassName: "active",
};

Droppable.propTypes = {
  className: propTypes.string,
  activeClassName: propTypes.string,
  onDrop: propTypes.func.isRequired,
  children: propTypes.node.isRequired,
};

export default Droppable;
