import PropTypes from "prop-types";

export const headerEntryShape = PropTypes.shape({
  displayName: PropTypes.node, // what to show in header
  key: PropTypes.string.isRequired, // key to get value from entry object and sort key
  formatFn: PropTypes.func, // optional formatting function for what to display in cell given an entry
  sortFn: PropTypes.func, // optional function to sort values by
  headerClassName: PropTypes.string, // class to apply to column header
  bodyClassName: PropTypes.string, // class to apply to body cells in the column
  width: PropTypes.number, // specify column width as a percentage
  nonSortable: PropTypes.bool, // if true, column will not be sortable
});

// can be any object that has keys that match the header keys
export const entryShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const tableShape = {
  headers: PropTypes.arrayOf(headerEntryShape),
  entries: PropTypes.arrayOf(entryShape),
  onRowClick: PropTypes.func,
  draggable: PropTypes.bool,
  rowHighlightFn: PropTypes.func,
  additionalRows: PropTypes.node,
  showRowNumbers: PropTypes.bool,
};

export const tableDefaultProps = {
  headers: [],
  entries: [],
  onRowClick: undefined,
  draggable: false,
  rowHighlightFn: () => {},
  additionalRows: undefined,
  showRowNumbers: false,
};
