import _ from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { KvInput } from "../shared";
import { convertSequenceStepsToYaml } from "./helpers";

const SuiteWorkflow = ({ steps }) => {
  const [startUrl, setStartUrl] = useState("");
  if (!steps) return "";

  const stepsBySequence = _.groupBy(steps, "sequenceIndex");

  let allSteps = "";
  Object.values(stepsBySequence).forEach((sequenceSteps) => {
    const stepsInOrder = _.orderBy(sequenceSteps, "stepPosition");
    allSteps += convertSequenceStepsToYaml(stepsInOrder, startUrl);
  });

  return (
    <>
      <KvInput
        name="Start Url"
        label="Start Url"
        value={startUrl}
        onChange={(e) => setStartUrl(e.target.value)}
      />
      <pre>{allSteps}</pre>
    </>
  );
};

SuiteWorkflow.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default SuiteWorkflow;
