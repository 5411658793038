import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import useIsVisible from "./hooks/useIsVisible";

// don't render children until the component is visible

const RenderWhenVisible = ({ children }) => {
  const ref = useRef();
  const [shouldRender, setShouldRender] = useState(false);
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (!shouldRender && isVisible) setShouldRender(true);
  }, [isVisible]);

  return <div ref={ref}>{shouldRender ? children : ""}</div>;
};

RenderWhenVisible.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RenderWhenVisible;
