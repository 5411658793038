import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "./Auth0/Auth0Wrapper";

const SignIn = () => {
  const { client, isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (client) {
      if (isAuthenticated) {
        window.location.href = "/";
      } else {
        const organization = searchParams.get("organization");
        const invitation = searchParams.get("invitation");
        const authorizationParams = {
          ...(organization ? { organization } : {}),
          ...(invitation ? { invitation } : {}),
        };
        client.loginWithRedirect({ authorizationParams });
      }
    }
  }, [client, isAuthenticated]);

  return "";
};

export default SignIn;
