import PropTypes from "prop-types";

const iconMap = {
  Home: "home",

  Documents: "folders",
  Reports: "chart-pie",
  Search: "search",
  Settings: "cog",

  ManagedRun: "square-list",

  Folder: "folder",
  FolderGrid: "folder-grid",
  OpenFolder: "folder-open",
  EmptyDocument: "file",
  Preview: "eye",
  Document: "file-alt",
  DocumentUpload: "file-upload",

  Goto: "search-location",
  Upload: "file-upload",
  Export: "folder-download",
  Import: "file-import",
  Sync: "arrow-right-arrow-left",
  Download: "download",
  Copy: "copy",

  Cabinet: "archive",
  Approved: "badge-check",
  Word: "file-word",
  Pdf: "file-pdf",
  Edit: "pencil",
  Share: "share-alt",
  Delete: "trash-alt",
  Trash: "trash-alt",
  Close: "times",
  Date: "calendar-days",

  Expand: "chevron-down",
  Collapse: "chevron-up",

  Reveal: "chevron-right",
  Hide: "chevron-down",

  Filter: "filter",
  CheckMark: "check",
  Pin: "thumbtack",
  Placeholder: "bookmark",
  Tag: "tag",
  Tags: "tags",
  BarChart: "chart-bar",
  GraphUp: "chart-line",
  Pencil: "pencil",
  Metadata: "table",
  VertDots: "ellipsis-v",
  List: "bars",
  Plus: "plus",
  Create: "plus",
  ArrowLeft: "arrow-left",
  Back: "arrow-left",
  Next: "arrow-right",
  Asc: "sort-amount-up-alt",
  Desc: "sort-amount-down",
  Bell: "bell",

  User: "user",
  Manager: "user-shield",

  ChevronDown: "chevron-down",
  Move: "share",
  Alias: "copy",
  Alert: "circle-exclamation",
  XML: "file-code",
  Zip: "file-zipper",
  Excel: "file-excel",
  PowerPoint: "file-powerpoint",
  TXT: "file-lines",
  Other: "file",
  Support: "question-circle",
  Desktop: "desktop",
  Review: "thumbs-up",
  AcceptReview: "thumbs-up",
  RejectReview: "thumbs-down",
  CancelReview: "trash-alt",
  Approval: "badge-check",
  Author: "pen",
  Sign: "signature",
  Save: "floppy-disk",
  Cancel: "x",
  Reject: "circle-xmark",
  Test: "flask",
  PassingResult: "circle-check",
  FailingResult: "circle-xmark",
  Refresh: "rotate",
  Play: "play",
  Release: "rocket-launch",
  Remove: "circle-x",
  ChangeControl: "files",
  Requirements: "ballot-check",
  Traceability: "square-share-nodes",
  Pending: "cog",
  UAT: "vial-circle-check",

  TestRunner: "user-robot",
};

const Icon = ({
  icon,
  className,
  solid,
  fixed,
  option,
  size,
  ...restProps
}) => {
  const fullClassName = [
    "kv-icon",
    solid ? "fas" : option,
    `fa-${iconMap[icon] || icon}`,
    fixed && "fa-fw",
    size && `fa-${size}x`,
    className,
  ]
    .filter((s) => !!s)
    .join(" ");

  return <i className={fullClassName} width="32" height="32" {...restProps} />;
};

Icon.defaultProps = {
  className: "",
  option: "fal",
  solid: false,
  fixed: false,
  size: undefined,
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  option: PropTypes.string,
  solid: PropTypes.bool,
  fixed: PropTypes.bool,
  size: PropTypes.oneOf([1, 2, 3, 4, 5, undefined]),
};

export const IconSpacer = ({ className, icon, ...restProps }) => (
  <Icon icon={icon} className={`invisible ${className}`} {...restProps} />
);
IconSpacer.defaultProps = { className: "", icon: "circle" };
IconSpacer.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
};

export default Icon;
