import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Form } from "reactstrap";
import { KvInput, Loading } from ".";

const SingleInputForm = ({
  onSubmit,
  onCancel,
  loading,
  defaultValue,
  buttonColor,
  formClassName,
  ...restProps
}) => {
  const [value, setValue] = useState(defaultValue);

  const submit = () => {
    onSubmit(value);
  };

  return (
    <Form
      className={`kv-single-input-form d-flex align-items-center ${formClassName}`}
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <KvInput
        onChange={(e) => setValue(e.target.value)}
        inline
        autoFocus
        {...{ defaultValue, ...restProps }}
      />
      {loading ? (
        <Loading size="sm" className="ms-3 align-self-center" />
      ) : (
        <>
          <Button
            size="small"
            color={buttonColor}
            className="ms-3 mx-2"
            type="submit"
          >
            Save
          </Button>
          {onCancel && (
            <Button color="plain-block" onClick={onCancel} className="px-1">
              Cancel
            </Button>
          )}
        </>
      )}
    </Form>
  );
};

SingleInputForm.defaultProps = {
  loading: false,
  defaultValue: "",
  onCancel: undefined,
  buttonColor: "success",
  formClassName: "d-flex align-items-end",
};
SingleInputForm.propTypes = {
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buttonColor: PropTypes.string,
  formClassName: PropTypes.string,
};

export default SingleInputForm;
