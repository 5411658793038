import _ from "lodash";
import { useEffect } from "react";
import { Input, Table } from "reactstrap";
import { useLazyQuery } from "@apollo/client";
import { GET_LOOKER_DASHBOARDS } from "./data";
import Loading from "../shared/Loading";
import { useLookerSync } from "./ContextProvider";

const lookerDomain = "http://kivo.cloud.looker.com";

const Dashboards = () => {
  const [getDashboards, { data }] = useLazyQuery(GET_LOOKER_DASHBOARDS);
  const {
    sourceEnv,
    setSourceEnv,
    selectedDashboardIds,
    setSelectedDashboardIds,
  } = useLookerSync();

  useEffect(() => {
    setSelectedDashboardIds([]);
    getDashboards({ variables: { env: sourceEnv } });
  }, [sourceEnv]);

  const boards =
    data && _.orderBy(data.getLookerDashboards, "path", "folderName", "title");

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3>Looker Dashboards – {_.startCase(sourceEnv)}</h3>

        <div>
          <Input
            type="select"
            value={sourceEnv}
            onChange={(e) => setSourceEnv(e.target.value)}
          >
            {["sandbox", "development", "demo", "validation", "production"].map(
              (env) => (
                <option key={env} value={env}>
                  {_.startCase(env)}
                </option>
              )
            )}
          </Input>
        </div>
      </div>

      {boards ? (
        <>
          <Table>
            <thead>
              <tr>
                <td>
                  <Input
                    type="checkbox"
                    checked={_.isEqual(
                      selectedDashboardIds.sort(),
                      boards.map((b) => b.id).sort()
                    )}
                    onChange={(e) => {
                      const newIds = e.target.checked
                        ? boards.map((b) => b.id).sort()
                        : [];
                      setSelectedDashboardIds(newIds);
                    }}
                  />
                </td>
                <th>Title</th>
                <th>Folder</th>
                <th>Id</th>
                <th>Slug</th>
              </tr>
            </thead>
            <tbody>
              {boards.map((b) => (
                <tr key={b.id}>
                  <td>
                    <Input
                      type="checkbox"
                      checked={selectedDashboardIds.includes(b.id)}
                      onChange={(e) => {
                        const newIds = e.target.checked
                          ? [...selectedDashboardIds, b.id]
                          : selectedDashboardIds.filter((id) => id !== b.id);

                        setSelectedDashboardIds(newIds);
                      }}
                    />
                  </td>
                  <td>
                    <a
                      href={`${lookerDomain}/dashboards/${b.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {b.title}
                    </a>
                  </td>
                  <td>{b.path || b.folderName}</td>
                  <td>{b.id}</td>
                  <td>{b.slug}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {!boards.length && (
            <p className="p-4 text-muted text-center fst-italic">
              No boards found for {_.startCase(sourceEnv)}.
            </p>
          )}
        </>
      ) : (
        <Loading full className="my-4" />
      )}
    </>
  );
};

export default Dashboards;
