import { gql, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import KvModal from "../shared/KvModal";

const DELETE_TEST_PLAN = gql`
  mutation DeleteTestPlan($id: String!) {
    deleteTestPlan(id: $id)
  }
`;

const DeleteModal = ({ isOpen, testPlan, onCancel }) => {
  const [deletePlan] = useMutation(DELETE_TEST_PLAN, {
    variables: { id: testPlan?.id },
    refetchQueries: ["GetAllTestPlans"],
  });

  if (!testPlan) return "";

  const onConfirm = async () => {
    await deletePlan();
    onCancel();
  };

  return (
    <KvModal
      isOpen={isOpen}
      header="Delete Test Plan"
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmColor="danger"
    >
      Are you sure you want to delete {testPlan.name}?
    </KvModal>
  );
};

DeleteModal.defaultProps = { testPlan: undefined };

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  testPlan: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  onCancel: PropTypes.func.isRequired,
};

export default DeleteModal;
