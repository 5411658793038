import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "../Components/shared";
import { useUser } from "./UserContextProvider";

const NavLink = ({ to, icon, children, className }) => {
  const { pathname } = useLocation();
  const isActive = to === "/" ? to === pathname : pathname.startsWith(to);

  const classNames = [];
  if (isActive) classNames.push("text-info");
  if (className) classNames.push(className);

  return (
    <Link to={to} className={classNames.join(" ")}>
      <Icon icon={icon} /> {children}
    </Link>
  );
};

NavLink.defaultProps = {
  className: "",
};

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const MainNav = () => {
  const { user } = useUser();

  if (!user) return <header className="kv-header" />;

  const assembledNavItems = () => {
    const items = [
      <NavLink key="Tests" to="/" icon="Test">
        Tests
      </NavLink>,
      <NavLink key="Releases" to="/releases" icon="Release">
        Releases
      </NavLink>,
      <NavLink key="Snaps" to="/snapshots" icon="database">
        Snapshots
      </NavLink>,
      <NavLink key="Managed Runs" to="/runs" icon="ManagedRun">
        Managed Runs
      </NavLink>,
      <NavLink key="Looker" to="/looker" icon="Reports">
        Looker
      </NavLink>,
      <NavLink key="Test Runner" to="/test-runner" icon="TestRunner">
        Test Runner
      </NavLink>,
    ];

    return items;
  };

  return (
    <header className="kv-header">
      <Link to="/">
        <img
          className="kv-header-logo"
          src="/gemstone.png"
          alt="gemstone logo"
        />
      </Link>

      {assembledNavItems()}
    </header>
  );
};

export default MainNav;
