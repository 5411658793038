import { gql, useQuery } from "@apollo/client";
import { useAtom } from "jotai";
import compact from "lodash/compact";
import orderBy from "lodash/orderBy";
import {
  Col,
  Container,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import { displayDateTime } from "../../../util/dateUtils";
import Result from "../../shared/Result";
import VideoThumbnail from "../../shared/VideoThumbnail";
import { displayModalContents, sidePanelResultAtom } from "../atoms";
import "./SidePanel.scss";
import TestStep from "./TestStep";

const GET_TEST_RESULT = gql`
  query GetTestResult($id: ID!) {
    getTestResult(id: $id) {
      id
      apiCommitSha
      webCommitSha
      executionFinishedAt
      executionStartedAt
      name
      passing
      screenshot {
        id
        url
      }
      video {
        id
        url
      }
      testSteps {
        id
        passing
        sequence
        screenshot {
          id
          url
        }
        result
        notesRaw
        target
        value
        command
        error
      }
    }
  }
`;

const SuiteResultSidePanel = () => {
  const [selectedResult, setSelectedResultResult] =
    useAtom(sidePanelResultAtom);
  const [, setDisplayModalContents] = useAtom(displayModalContents);

  const { data } = useQuery(GET_TEST_RESULT, {
    variables: { id: selectedResult },
    skip: !selectedResult,
  });

  const onClose = () => setSelectedResultResult(undefined);
  const testResult = data?.getTestResult || selectedResult;

  const openScreenshotModal = (screenshotUrl) => {
    const allScreenshots = compact(
      testResult.testSteps.map((step) => step.screenshot?.url)
    ).map((src) => ({ src, type: "image" }));

    const contents = {
      title: `${testResult.name} Screenshots`,
      items: allScreenshots,
      currentItem: allScreenshots.find(({ src }) => src === screenshotUrl),
    };
    setDisplayModalContents(contents);
  };

  if (!testResult) return null;

  return (
    <Offcanvas
      isOpen={!!testResult}
      direction="end"
      fade={false}
      backdrop={false}
      toggle={onClose}
      className="kv-side-panel"
    >
      <OffcanvasHeader toggle={onClose}>{testResult.name}</OffcanvasHeader>
      <OffcanvasBody>
        <Container className="ps-0">
          <Row>
            <Col>
              <Row>
                <Col xs={4}>
                  <span className="fw-bold">Result:</span>
                </Col>
                <Col>
                  <Result isPassing={testResult.passing} />
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <span className="fw-bold">Started:</span>
                </Col>
                <Col>{displayDateTime(testResult.executionStartedAt)}</Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <span className="fw-bold">Finished:</span>
                </Col>
                <Col>{displayDateTime(testResult.executionFinishedAt)}</Col>
              </Row>
            </Col>
            <Col xs={6}>
              <VideoThumbnail
                imgSrc={testResult.screenshot?.url}
                onClick={() =>
                  setDisplayModalContents({
                    currentItem: { src: testResult?.video?.url, type: "video" },
                    title: testResult.name,
                  })
                }
              />
              <span className="d-flex justify-content-center">Video</span>
            </Col>
          </Row>
        </Container>

        <h5 className="mt-5">Steps</h5>
        <div className="ms-2">
          {orderBy(testResult.testSteps, (s) => Number(s.sequence)).map(
            (step) => (
              <TestStep
                key={step.id}
                step={step}
                seeScreenshot={openScreenshotModal}
              />
            )
          )}
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default SuiteResultSidePanel;
