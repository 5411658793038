import get from "lodash/get";
import { Draggable } from "../DragNDrop";
import { tableDefaultProps, tableShape } from "./tableShapes";

const TableBody = ({
  headers,
  entries,
  onRowClick,
  draggable,
  rowHighlightFn,
  additionalRows,
  showRowNumbers,
}) => {
  const getClassName = (entry) =>
    [
      onRowClick ? "cursor-pointer" : "",
      rowHighlightFn(entry) ? "bg-active-row" : "",
    ].join(" ");

  const createCells = (entry) => {
    const cells = headers.map(({ key, formatFn, bodyClassName }) => {
      const value = formatFn ? formatFn(entry) : get(entry, key);
      return (
        <td
          key={`bodykey-${key}-${value}-${entry.id}`}
          className={bodyClassName}
        >
          {value}
        </td>
      );
    });
    if (showRowNumbers) {
      cells.unshift(
        <td key={`bodykey-row-number-${entry.id}`}>{entry.idx + 1}</td>
      );
    }
    return cells;
  };

  const allEntries = entries.map((e, idx) => ({ ...e, idx }));

  return (
    <tbody>
      {allEntries.map((entry) =>
        draggable ? (
          <Draggable
            tag="tr"
            payload={JSON.stringify(entry)}
            key={`bodyrow--${entry.id}`}
            onClick={(e) => onRowClick?.(e, entry)}
            className={getClassName(entry)}
          >
            {createCells(entry)}
          </Draggable>
        ) : (
          <tr
            key={`bodyrow--${entry.id}`}
            onClick={(e) => onRowClick?.(e, entry)}
            className={getClassName(entry)}
          >
            {createCells(entry)}
          </tr>
        )
      )}
      {additionalRows}
    </tbody>
  );
};

TableBody.defaultProps = tableDefaultProps;
TableBody.propTypes = tableShape;

export default TableBody;
