import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";

const LoadingButton = ({ isLoading, children, color, ...rest }) => (
  <Button disabled={isLoading} color={color} {...rest}>
    <div className="position-relative">
      {isLoading && (
        <Spinner
          className="position-absolute mx-auto mt-auto mb-auto top-0 bottom-0 start-0 end-0"
          as="span"
          role="status"
          aria-hidden="true"
          size="sm"
        />
      )}
      <span className={isLoading ? "invisible" : "visible"}>{children}</span>
    </div>
  </Button>
);

LoadingButton.defaultProps = { color: "primary" };
LoadingButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
};

export default LoadingButton;
