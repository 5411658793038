import { useMutation, gql } from "@apollo/client";
import PropTypes from "prop-types";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  AccordionBody,
  AccordionHeader,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledAccordion,
} from "reactstrap";

const CREATE_RELEASE = gql`
  mutation CreateRelease($input: ReleaseCreateInput!) {
    createRelease(input: $input) {
      name
      apiCommitSha
      active
      apiCompareLink
      webCommitSha
      webCompareLink
      releaseDate
    }
  }
`;

const NewReleaseForm = ({ onComplete }) => {
  const [releaseAttrs, setReleaseAttrs] = useState();
  const [createRelease] = useMutation(CREATE_RELEASE, {
    refetchQueries: ["GetAllReleases"],
  });

  const handleChange = (value, property) => {
    setReleaseAttrs((prev) => ({ ...prev, [property]: value }));
  };

  const create = () => {
    createRelease({ variables: { input: { ...releaseAttrs } } });
    onComplete();
  };

  return (
    <Card className="w-25">
      <CardHeader>New Release</CardHeader>
      <CardBody>
        <Container>
          <Row className="align-items-center">
            <Col>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  id="name"
                  onChange={(e) => handleChange(e.target.value, "name")}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup check className="pt-3">
                <Label check>
                  <Input
                    className="me-2"
                    type="checkbox"
                    onChange={(e) => handleChange(e.target.checked, "active")}
                  />{" "}
                  Active
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <UncontrolledAccordion flush>
            <AccordionHeader targetId="1">Past Release</AccordionHeader>
            <AccordionBody accordionId="1">
              <FormGroup>
                <Label for="web-sha">Web Sha</Label>
                <Input
                  id="web-sha"
                  onChange={(e) => handleChange(e.target.value, "webCommitSha")}
                />
              </FormGroup>
              <FormGroup>
                <Label for="api-sha">Api Sha</Label>
                <Input
                  id="api-sha"
                  onChange={(e) => handleChange(e.target.value, "apiCommitSha")}
                />
              </FormGroup>
              <FormGroup>
                <Label for="web-link">Web Compare Link</Label>
                <Input
                  id="web-link"
                  onChange={(e) =>
                    handleChange(e.target.value, "webCompareLink")
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="api-link">Api Compare Link</Label>
                <Input
                  id="api-link"
                  onChange={(e) =>
                    handleChange(e.target.value, "apiCompareLink")
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Release Date</Label>
                <ReactDatePicker
                  customInput={<Input />}
                  onChange={(d) => handleChange(d, "releaseDate")}
                  selected={releaseAttrs?.releaseDate}
                />
              </FormGroup>
            </AccordionBody>
          </UncontrolledAccordion>
        </Container>
      </CardBody>
      <CardFooter>
        <Button type="submit" color="plain" onClick={onComplete}>
          Cancel
        </Button>
        <Button className="ms-3" type="submit" color="primary" onClick={create}>
          Create
        </Button>
      </CardFooter>
    </Card>
  );
};

NewReleaseForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
};

export default NewReleaseForm;
