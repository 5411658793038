import PropTypes from "prop-types";
import { Table } from "reactstrap";
import Result from "../shared/Result";
import { managedRunShape } from "../shared/shapes";
import SuiteResults from "./SuiteResults";

const RunResults = ({ run }) => (
  <div className="kv-run-results">
    {!!run.suiteResults?.length && (
      <>
        <h3>Results for Selected Suites</h3>
        <SuiteResults suiteResults={run.suiteResults} />
      </>
    )}

    {!!run.testResults?.length && (
      <>
        <h3>Results for Selected Tests</h3>
        <Table>
          <tbody>
            {run.testResults.map((result) => (
              <tr key={result.id}>
                <td>{result.name}</td>
                <td>
                  {result.executionFinishedAt ? (
                    <Result isPassing={result.passing} />
                  ) : (
                    "Pending"
                  )}
                </td>
                <td>{result.executionStartedAt}</td>
                <td>{result.executionFinishedAt}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    )}
  </div>
);

RunResults.propTypes = { run: PropTypes.shape(managedRunShape).isRequired };

export default RunResults;
