import { gql, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";
import { Icon } from "../shared";
import KvButton from "../shared/KvButton";
import Result from "../shared/Result";

const REMOVE_SUITE_RESULT = gql`
  mutation RemoveSuiteResult($releaseId: ID!, $suiteResultId: ID!) {
    removeSuiteResult(releaseId: $releaseId, suiteResultId: $suiteResultId) {
      id
      suiteResults {
        id
      }
    }
  }
`;

const SuiteResults = ({ releaseId, suiteResults }) => {
  const [removeSuiteResult, { loading }] = useMutation(REMOVE_SUITE_RESULT);

  const oqTests = suiteResults.filter(
    (t) => t.testSuite?.folder?.testType === "oq"
  );
  const uatTests = suiteResults.filter(
    (t) => t.testSuite?.folder?.testType === "uat"
  );
  const unknownTests = suiteResults.filter(
    (t) => !["oq", "uat"].includes(t.testSuite?.folder?.testType)
  );

  const removeResult = (id) => {
    removeSuiteResult({
      variables: {
        releaseId,
        suiteResultId: id,
      },
    });
  };

  const testGroups = [
    ["OQ", oqTests],
    ["UAT", uatTests],
    ["Unknown", unknownTests],
  ];

  return (
    <>
      <h5 className="text-gray-medium">Tests</h5>
      <Container className="gap-3 p-0">
        {testGroups.map(([title, tests]) =>
          tests.length > 0 ? (
            <>
              <div className="fw-bold my-3 text-gray-medium">{title}</div>
              {tests.map((result) => (
                <Row key={result.id}>
                  <Col xs={8}>
                    <span>{result.name}</span>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-between gap-2">
                    <Result isPassing={result.passing} />
                    <KvButton
                      color="plain"
                      onClick={() => removeResult(result.id)}
                      isLoading={!!loading}
                    >
                      <Icon icon="Cancel" />
                    </KvButton>
                  </Col>
                </Row>
              ))}
            </>
          ) : (
            ""
          )
        )}
      </Container>
    </>
  );
};

SuiteResults.propTypes = {
  suiteResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ).isRequired,
  releaseId: PropTypes.string.isRequired,
};

export default SuiteResults;
