import PropTypes from "prop-types";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import { useDragNDrop } from "./ContextProvider";

const Draggable = ({
  children,
  payload,
  className,
  tag: CustomTag,
  disabled,
  ...rest
}) => {
  const [id] = useState(`draggable-${uuid()}`);
  const { active, setActive, setActivePayload } = useDragNDrop();

  return (
    <CustomTag
      id={id}
      className={`kv-draggable ${active ? "active" : ""} ${className}`}
      draggable={!disabled}
      onDragEnd={() => {
        setActive(false);
        setActivePayload(null);
      }}
      onDragStart={(e) => {
        setActive(true);
        setActivePayload(payload);
        e.dataTransfer.effectAllowed = "copy";
        e.dataTransfer.setData("text/plain", payload);
        e.stopPropagation();
      }}
      {...rest}
    >
      {children}
    </CustomTag>
  );
};

Draggable.defaultProps = {
  className: "",
  tag: "div",
  disabled: false,
};

Draggable.propTypes = {
  children: PropTypes.node.isRequired,
  payload: PropTypes.string.isRequired,
  className: PropTypes.string,
  tag: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Draggable;
