import PropTypes from "prop-types";
import {
  FormFeedback,
  FormText,
  Label,
  Input as ReactStrapInput,
} from "reactstrap";
import IconCheckbox from "./IconCheckbox";
import ToggleSwitch from "./ToggleSwitch";

export const Required = ({ required }) => {
  if (!required) return "";

  return (
    <span className="ps-1 text-danger" aria-label="required">
      *
    </span>
  );
};

Required.defaultProps = { required: false };
Required.propTypes = { required: PropTypes.bool };

const WrappedLabel = ({ children, required, ...restProps }) => {
  const label = <Label {...restProps}>{children}</Label>;

  return required ? (
    <div className="d-flex">
      {label}
      <Required required />
    </div>
  ) : (
    label
  );
};

WrappedLabel.defaultProps = { required: false };
WrappedLabel.propTypes = {
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
};

// allow passing of options to selects
const applyOptionsProps = (type, props) => {
  const { children, options } = props;
  if (type !== "select" || !Array.isArray(options) || children) return props;

  const opts = options.map((o) => {
    let value;
    let text;

    if (Array.isArray(o)) {
      [value] = o;
      text = o[1] || value;
    } else {
      value = o;
      text = o;
    }

    return (
      <option key={value} value={value}>
        {text}
      </option>
    );
  });

  return { ...props, options: undefined, children: opts };
};

// these show the label after the input (unless lableFirst is set)
const checkboxLikeTypes = ["radio", "checkbox", "toggle", "icon-checkbox"];

const Input = ({
  label,
  id,
  name,
  hint,
  error,
  groupClassName,
  inline,
  type,
  labelFirst,
  ...restProps
}) => {
  const checkboxLike = checkboxLikeTypes.includes(type);
  let inputId = id || name;
  if (checkboxLike) inputId = `${inputId}-${restProps.value}`;

  const labelEl = label && (
    <WrappedLabel for={inputId} required={restProps.required}>
      {label}
    </WrappedLabel>
  );

  const outerClassName = [
    "kv-input-wrapper",
    `kv-input-wrapper__${type}`,
    groupClassName.match(/\bmb-/) || inline ? "" : "mb-3",
    groupClassName,
    checkboxLike && labelFirst ? "kv-input-wrapper--label-first" : "",
  ].join(" ");

  const props = applyOptionsProps(type, {
    id: inputId,
    name,
    invalid: !!error,
    ...restProps,
  });

  let contents;

  switch (type) {
    case "toggle":
      contents = <ToggleSwitch {...props} />;
      break;
    case "icon-checkbox":
      contents = <IconCheckbox {...props} />;
      break;
    default:
      contents = <ReactStrapInput type={type} {...props} />;
  }

  return (
    <div className={outerClassName}>
      {!(checkboxLike && !labelFirst) && labelEl}
      {contents}
      {checkboxLike && !labelFirst && labelEl}
      {error && <FormFeedback>{error}</FormFeedback>}
      {hint && <FormText>{hint}</FormText>}
    </div>
  );
};

Input.defaultProps = {
  error: undefined,
  hint: undefined,
  label: undefined,
  id: undefined,
  groupClassName: "",
  inline: false,
  type: "text",
  labelFirst: false,
};

Input.propTypes = {
  error: PropTypes.node,
  hint: PropTypes.node,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  groupClassName: PropTypes.string,
  inline: PropTypes.bool,
  type: PropTypes.string,
  labelFirst: PropTypes.bool,
};

export default Input;
