import PropTypes from "prop-types";
import { SortHeader } from "./SortableTable";
import { headerEntryShape } from "./tableShapes";

const TableHeader = ({ headers, sortable, showRowNumbers }) => {
  const allHeaders = showRowNumbers
    ? [{ displayName: "#", key: "idx" }].concat(headers)
    : headers;

  const headerElements = allHeaders.map((h) => {
    const sortHeader = sortable && !h.nonSortable;
    const props = {
      key: `headerkey-${h.key}`,
      style: { width: h.width ? `${h.width}%` : undefined },
      className: h.headerClassName,
    };
    if (sortHeader) props.sortKey = h.key;

    return sortHeader ? (
      <SortHeader {...props}>{h.displayName}</SortHeader>
    ) : (
      <th {...props}>{h.displayName}</th>
    );
  });

  return (
    <thead>
      <tr>{headerElements}</tr>
    </thead>
  );
};

TableHeader.defaultProps = {
  headers: [],
  showRowNumbers: false,
  sortable: false,
};

TableHeader.propTypes = {
  headers: PropTypes.arrayOf(headerEntryShape),
  showRowNumbers: PropTypes.bool,
  sortable: PropTypes.bool,
};

export default TableHeader;
