import PropTypes from "prop-types";
import { Spinner } from "reactstrap";

const Loading = ({ full, inline, ...rest }) => {
  if (full) {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <Spinner {...rest} />
      </div>
    );
  }

  return <Spinner {...rest} />;
};

Loading.defaultProps = { full: false, inline: false };
Loading.propTypes = { full: PropTypes.bool, inline: PropTypes.bool };

export default Loading;
