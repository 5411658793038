import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useUser } from "../../Layout/UserContextProvider";
import { Loading } from "../shared";
import { useAuth } from "./Auth0/Auth0Wrapper";

const NoUser = ({ message, children }) => {
  const { client, isAuthenticated } = useAuth();
  const { user } = useUser();
  const [authOk, setAuthOk] = useState(false);

  useEffect(() => {
    setAuthOk(user && isAuthenticated);
  }, [user, isAuthenticated]);

  if (authOk) return <Navigate to="/" />;

  const showPage = client && !(user && isAuthenticated);

  return showPage ? (
    <div className="kv-main">
      <h2>Sign in error</h2>

      {children || (
        <>
          {message}

          <p>
            If you&apos;re having trouble signing in,{" "}
            <Link to="/sign-out">click here to try again</Link>, or contact
            support if the problem persists.
          </p>

          <Link to="/sign-out" className="btn btn-primary">
            Sign in
          </Link>
        </>
      )}
    </div>
  ) : (
    <Loading full />
  );
};

NoUser.defaultProps = { children: undefined, message: "" };
NoUser.propTypes = { children: PropTypes.node, message: PropTypes.node };

export default NoUser;
