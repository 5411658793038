import { gql, useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { useParams } from "react-router-dom";
import KvButton from "../shared/KvButton";
import KvInput from "../shared/KvInput";
import SyncGhostDefinitionsButton from "../shared/SyncTestDefinitionsButton";

const GET_FOLDER_WITH_SUITES = gql`
  query GetFolders_SuiteSelector {
    getAllFolders {
      id
      name
      testSuites {
        id
        name
        ghostId
      }
    }
  }
`;

const CREATE_SEQUENCE_STEP = gql`
  mutation CreateSequenceStep($sequenceStep: SequenceStepInput!) {
    createTestSequenceStep(sequenceStep: $sequenceStep) {
      id
      testPlan {
        id
        sequenceSteps {
          sequenceIndex
          stepPosition
          testSuite {
            id
            name
            ghostId
          }
        }
      }
    }
  }
`;

const SuiteSelector = ({ sequenceIndex }) => {
  const [folder, setFolder] = useState();
  const [selectedSuites, setSelectedSuites] = useState([]);
  const { data: folders } = useQuery(GET_FOLDER_WITH_SUITES);
  const [createSequenceStep, { loading }] = useMutation(CREATE_SEQUENCE_STEP);
  const { testPlanId } = useParams();

  const allFolders = _.orderBy(folders?.getAllFolders, "name");

  if (allFolders.length && !folder) setFolder(allFolders[0]);

  const createSteps = async () => {
    for (const suite of selectedSuites) {
      await createSequenceStep({
        variables: {
          sequenceStep: {
            sequenceIndex,
            testPlanId,
            testSuiteId: suite.id,
          },
        },
      });
    }
  };

  const toggleSuite = (suite) =>
    setSelectedSuites((prev) =>
      prev.includes(suite)
        ? prev.filter((s) => s !== suite)
        : prev.concat(suite)
    );

  if (sequenceIndex == null) return "";

  return (
    <div>
      <div className="mb-2">Add tests to Sequence {sequenceIndex + 1}</div>
      <div className="d-flex align-items-center mb-3">
        <KvInput
          type="select"
          name="Select Folder"
          inline
          value={folder?._id}
          onChange={(e) =>
            setFolder(allFolders.find((f) => f.id === e.target.value))
          }
          options={allFolders.map((f) => [f.id, f.name])}
        />

        <SyncGhostDefinitionsButton
          className="ms-5"
          icon="Sync"
          color="plain"
        />
      </div>

      <div>
        <KvInput
          type="checkbox"
          name="Select All"
          label="Select All"
          inline
          onChange={() =>
            setSelectedSuites((prev) =>
              prev.length !== folder?.testSuites.length
                ? folder?.testSuites
                : []
            )
          }
        />
        {folder?.testSuites.map((s) => (
          <KvInput
            key={s.id}
            onChange={() => toggleSuite(s)}
            type="checkbox"
            name={s.name}
            inline
            id={s.id}
            checked={selectedSuites.includes(s)}
            label={s.name}
          />
        ))}
        <KvButton
          onClick={() => createSteps(folder.testSuites)}
          icon="Plus"
          color="primary"
          className="mt-3"
          isLoading={loading}
        >
          Add Suites
        </KvButton>
      </div>
    </div>
  );
};

SuiteSelector.defaultProps = {
  sequenceIndex: undefined,
};

SuiteSelector.propTypes = {
  sequenceIndex: PropTypes.number,
};

export default SuiteSelector;
