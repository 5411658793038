const formatKey = (key) => `kivo.${process.env.NODE_ENV}.${key}`;

export const setItem = (key, value) => {
  window.localStorage.setItem(formatKey(key), JSON.stringify(value));
};

export const getItem = (key) => {
  const item = window.localStorage.getItem(formatKey(key));

  try {
    return JSON.parse(item);
  } catch (e) {
    return item;
  }
};

export const getObject = (key) => {
  try {
    const item = getItem(formatKey(key));
    if (
      item !== undefined &&
      item !== null &&
      item !== "undefined" &&
      item !== "null"
    ) {
      return JSON.parse(item);
    }
  } catch (e) {
    // noop
  }
  return undefined;
};

export const removeItem = (key) => {
  window.localStorage.removeItem(formatKey(key));
};
