import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createAuthClient,
  handleAuth,
  routeIsCallback,
  routeIsPublic,
  stashLastLocation,
} from "./helpers";

const Context = createContext();

const Auth0Wrapper = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [accessToken, setAccessToken] = useState();
  const [publicRoute, setPublicRoute] = useState();
  const [client, setClient] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const initialized = useRef(false);

  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    // can't pass "async ()" to useEffect
    async function authAsyncWrapper() {
      const newClient = await createAuthClient();

      try {
        if (routeIsCallback()) {
          await newClient.handleRedirectCallback();
        }

        const auth = await handleAuth(newClient);
        setAccessToken(auth.token?.access_token);
        setIsAuthenticated(auth.isAuthenticated);
      } catch (e) {
        if (!routeIsPublic() && !routeIsCallback()) {
          stashLastLocation();
          const redirect = e.message.match(/Login required/)
            ? "/sign-in"
            : "/no-user";
          // timeout to prevent bad loops and some UX oddities
          setTimeout(() => navigate(redirect), 250);
        }

        setIsAuthenticated(false);
      }

      setClient(newClient);
    }

    authAsyncWrapper();
  }, []);

  useEffect(() => setPublicRoute(routeIsPublic()), [location.pathname]);

  const value = useMemo(
    () => ({ accessToken, isAuthenticated, client, publicRoute }),
    [accessToken, isAuthenticated, client, publicRoute]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Auth0Wrapper.propTypes = { children: PropTypes.node.isRequired };

export const useAuth = () => useContext(Context);
export default Auth0Wrapper;
