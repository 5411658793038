import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  Button,
  CardImg,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Icon from "../../shared/Icon";
import { displayModalContents } from "../atoms";

/* Can populate by setting hte displayModalContents atom.
 *
 * Expects a structure like:
 * {
 *  title: String
 *  currentItem: {
 *    src: String
 *    type: "video" | "image"
 *  },
 *  items: [{
 *    src: String
 *    type: "video" | "image"
 *  }]
 * }
 *
 */

const DisplayModal = () => {
  const [contents, setContents] = useAtom(displayModalContents);
  const [currentItem, setCurrentItem] = useState();

  useEffect(() => {
    if (!contents?.currentItem) return;
    setCurrentItem(contents.currentItem);
  }, [contents]);

  if (!contents) return null;

  const nextItem = () => {
    const idx = contents.items.indexOf(currentItem);
    let item = contents.items[idx + 1];
    if (!item) [item] = contents.items;
    setCurrentItem(item);
  };

  const prevItem = () => {
    const idx = contents.items.indexOf(currentItem);
    let item = contents.items[idx - 1];
    if (!item) [item] = contents.items[contents.items.length - 1];
    setCurrentItem(item);
  };

  return (
    <Modal isOpen={!!contents} size="lg" toggle={() => setContents()}>
      <ModalHeader toggle={() => setContents()}>{contents?.title}</ModalHeader>
      <ModalBody>
        {currentItem?.type === "image" && <CardImg src={currentItem.src} />}
        {currentItem?.type === "video" && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video controls className="w-100">
            <source src={currentItem.src} />
          </video>
        )}
      </ModalBody>
      {contents?.items?.length && (
        <ModalFooter className="justify-content-center">
          <Button color="primary" onClick={prevItem}>
            <Icon icon="Back" />
          </Button>
          <span className="mx-3">
            {contents.items.indexOf(currentItem) + 1} / {contents.items.length}
          </span>
          <Button color="primary" onClick={nextItem}>
            <Icon icon="Next" />
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default DisplayModal;
