import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import SimpleTabs from "../shared/SimpleTabs";
import DisplayModal from "./DisplayModal/DisplayModal";
import FolderReport from "./FolderReport";
import SuiteResultSidePanel from "./SidePanel/SuiteResultSidePanel";
import SyncFolder from "./syncFolder";

export const GET_ALL_FOLDERS = gql`
  query GetAllFolders {
    getAllFolders {
      ghostId
      id
      name
      testType
    }
  }
`;

const TestResultsPage = () => {
  const [tab, setTab] = useState("oq");
  const { data } = useQuery(GET_ALL_FOLDERS);

  const folders = data?.getAllFolders || [];
  const selectedFolders = folders.filter((f) => f.testType === tab);

  const tabs = [
    ["oq", "OQ"],
    ["uat", "UAT"],
    [null, "Other"],
  ];

  return (
    <div className="kv-main--wrapper__scrollable">
      <div className="kv-main container-fluid kv-home pb-4" role="main">
        <SimpleTabs {...{ tab, setTab, tabs }} />
        {selectedFolders.map((f) => (
          <FolderReport key={f.id} folderId={f?.id} />
        ))}
        <SyncFolder />
      </div>
      <SuiteResultSidePanel />
      <DisplayModal />
    </div>
  );
};

export default TestResultsPage;
