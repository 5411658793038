import { gql, useMutation } from "@apollo/client";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { useToasts } from "../../Layout/ToastContextProvider";
import { KvInput } from "../shared";
import KvButton from "../shared/KvButton";

const ADD_JIRA_ISSUE_WITH_STATUS = gql`
  mutation AddJiraIssues($releaseId: ID!, $status: String!) {
    addJiraIssues(releaseId: $releaseId, status: $status) {
      id
      issues {
        id
        changeRisk
        iconUrl
        key
        summary
        type
        url
        subsystem
        addedToReleaseAt
      }
    }
  }
`;

const REMOVE_ISSUE_FROM_RELEASE = gql`
  mutation RemoveJiraIssue($releaseId: ID!, $issueId: ID!) {
    removeJiraIssue(releaseId: $releaseId, issueId: $issueId) {
      id
      issues {
        id
        changeRisk
        iconUrl
        key
        summary
        type
        url
        subsystem
      }
    }
  }
`;

const ADD_ISSUE_TO_RELEASE = gql`
  mutation AddJiraIssue($releaseId: ID!, $key: String!) {
    addJiraIssue(releaseId: $releaseId, key: $key) {
      id
      issues {
        id
        changeRisk
        iconUrl
        key
        summary
        type
        url
        subsystem
      }
    }
  }
`;

const Issues = ({ releaseId, issues }) => {
  const [filter, setFilter] = useState("all");
  const [jiraKey, setJiraKey] = useState("");
  const { removeErrorToast } = useToasts();
  const [removeIssue, { loading: removeLoading }] = useMutation(
    REMOVE_ISSUE_FROM_RELEASE
  );
  const [addJiraQAIssues, { loading: qaLoading }] = useMutation(
    ADD_JIRA_ISSUE_WITH_STATUS,
    {
      variables: {
        releaseId,
        status: "QA",
      },
    }
  );
  const [addJiraIssue, { loading: addLoading, error }] = useMutation(
    ADD_ISSUE_TO_RELEASE,
    {
      variables: {
        releaseId,
        key: jiraKey,
      },
    }
  );

  const removeIssueFromRelease = (issueId) =>
    removeIssue({
      variables: {
        releaseId,
        issueId,
      },
    });

  useEffect(() => {
    if (!error) return;
    removeErrorToast(error);
  }, [error]);

  const sortedIssues = _.orderBy(issues, [
    (issue) => issue.type,
    (issue) => issue.key,
  ]);

  const latestIssueTime = _.orderBy(
    issues,
    (i) => new Date(i.addedToReleaseAt),
    "desc"
  )[0]?.addedToReleaseAt;

  const newIssues = sortedIssues.filter(
    (i) => i.addedToReleaseAt === latestIssueTime
  );

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h5 className="text-gray-medium">Issues</h5>
          <div className="d-flex gap-3">
            <KvInput
              id={`release-${releaseId}-1`}
              type="radio"
              inline
              label={`Total: ${issues.length}`}
              name={`filter-${releaseId}`}
              checked={filter === "all"}
              onClick={() => setFilter("all")}
            />
            <KvInput
              id={`release-${releaseId}-2`}
              type="radio"
              inline
              label={`New: ${newIssues.length}`}
              name={`filter-${releaseId}`}
              checked={filter === "new"}
              onClick={() => setFilter("new")}
            />
          </div>
        </div>

        <KvButton
          className="mb-2"
          color="link"
          onClick={addJiraQAIssues}
          isLoading={qaLoading}
        >
          Add Issues On Validation
        </KvButton>
      </div>
      <Container>
        {(filter === "all" ? sortedIssues : newIssues).map((issue) => (
          <Row key={issue.id} className="d-flex align-items-start gap-2 mt-2">
            <Col xs={2} className="ps-0 container">
              <Row>
                <span id={`issue-${issue.id}`} className="col-2">
                  <img src={issue.iconUrl} className="pb-1" alt="issue-icon" />
                </span>
                <UncontrolledTooltip
                  target={`issue-${issue.id}`}
                  placement="left"
                >
                  {issue.type}
                </UncontrolledTooltip>
                <a
                  href={issue.url}
                  className="text-nowrap col-6"
                  target="_blank"
                  rel="noreferrer"
                >
                  {issue.key}
                </a>
              </Row>
            </Col>
            <Col xs={1}>
              <span>{issue.changeRisk}</span>
            </Col>
            <Col className="ps-0 text-truncate">
              <div className="text-truncate">{issue.summary}</div>
            </Col>
            <Col className="px-0 d-flex justify-content-end" xs={2}>
              <span>{issue.subsystem}</span>
              <KvButton
                color="plain"
                isLoading={removeLoading}
                onClick={() => removeIssueFromRelease(issue.id)}
                className="ms-3"
                icon="Cancel"
              />
            </Col>
          </Row>
        ))}
      </Container>
      <Form
        className="d-flex w-50 mt-4"
        onSubmit={(e) => {
          e.preventDefault();
          addJiraIssue();
        }}
      >
        <Input
          placeholder="KV-000"
          onChange={(e) => setJiraKey(e.target.value)}
          value={jiraKey}
        />
        <KvButton
          type="submit"
          onClick={addJiraIssue}
          disabled={!jiraKey}
          isLoading={addLoading}
          className="ms-3"
        >
          Add
        </KvButton>
      </Form>
      <span className="text-danger">{error?.message}</span>
    </>
  );
};

Issues.defaultProps = {
  issues: [],
};

Issues.propTypes = {
  releaseId: PropTypes.string.isRequired,
  issues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      summary: PropTypes.string,
    })
  ),
};

export default Issues;
