import PropTypes from "prop-types";
import { Nav, NavItem } from "reactstrap";

const SimpleTabs = ({ tab, setTab, tabs }) => (
  <Nav tabs>
    {tabs.map(([key, label]) => (
      <NavItem key={key} onClick={() => setTab(key)} className="cursor-pointer">
        <div className={`nav-link ${tab === key ? "active" : ""}`}>{label}</div>
      </NavItem>
    ))}
  </Nav>
);

SimpleTabs.defaultProps = {
  tab: undefined,
};

SimpleTabs.propTypes = {
  setTab: PropTypes.func.isRequired,
  tab: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default SimpleTabs;
