import gql from "graphql-tag";

export const SnapshotFields = gql`
  fragment SnapshotFields on Snapshot {
    id
    name
    description
    fileKey
    restores
    lastRestoredAt
    restoreStatus
    createdAt
    updatedAt
  }
`;

export const GET_SNAPSHOTS = gql`
  query GetAllSnapshots {
    getAllSnapshots {
      ...SnapshotFields
    }
  }
  ${SnapshotFields}
`;

export const CREATE_SNAPSHOT = gql`
  mutation CreateSnapshot($input: SnapshotInput!) {
    createSnapshot(input: $input) {
      ...SnapshotFields
    }
  }
  ${SnapshotFields}
`;

export const DELETE_SNAPSHOT = gql`
  mutation DeleteSnapshot($id: ID!) {
    deleteSnapshot(id: $id) {
      id
    }
  }
`;

export const RESTORE_SNAPSHOT = gql`
  mutation RestoreSnapshot($id: ID!) {
    restoreSnapshot(id: $id) {
      ...SnapshotFields
    }
  }
  ${SnapshotFields}
`;

export const UPDATE_SNAPSHOT = gql`
  mutation UpdateSnapshot($id: ID!, $input: SnapshotInput!) {
    updateSnapshot(id: $id, input: $input) {
      ...SnapshotFields
    }
  }
  ${SnapshotFields}
`;
