import PropTypes from "prop-types";
import { useMemo } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { v4 as uuid } from "uuid";
import Icon from "./Icon";
import LoadingButton from "./LoadingButton";

export const TooltipButton = ({
  disabled,
  disabledReason,
  wrapperClassName,
  tooltip: argTooltip,
  ...buttonProps
}) => {
  const wrapperId = useMemo(() => buttonProps.id || `wrapper-${uuid()}`, []);

  const tooltipMessage =
    disabled && disabledReason ? disabledReason : argTooltip;

  const tooltip = !!tooltipMessage && (
    <UncontrolledTooltip target={wrapperId}>
      {tooltipMessage}
    </UncontrolledTooltip>
  );

  return (
    <span id={wrapperId} className={wrapperClassName}>
      <KvButton {...{ disabled, ...buttonProps }} />
      {tooltip}
    </span>
  );
};

TooltipButton.defaultProps = {
  disabled: false,
  disabledReason: undefined,
  wrapperClassName: undefined,
  tooltip: "",
};

TooltipButton.propTypes = {
  disabled: PropTypes.bool,
  disabledReason: PropTypes.node,
  wrapperClassName: PropTypes.string,
  tooltip: PropTypes.node,
};

const wrapperError = () => {
  console.error("KvButton: use TooltipButton when using disabledReason");
  const error = new Error("KvButton: disabledReason without useWrapper");
  if (["test", "development"].includes(process.env.NODE_ENV)) throw error;
};

const KvButton = ({ useWrapper, ...restProps }) => {
  // if there is a tooltip, or may be in the future, the button must have a wrapper
  // (but this component will avoid this if possible as it disrupts the layout)
  if (useWrapper || restProps.disabledReason || restProps.wrapperClassName) {
    if (restProps.disabledReason && !useWrapper) wrapperError();
    return <TooltipButton {...restProps} />;
  }

  const { children, name, icon, ...buttonProps } = restProps;

  return (
    <LoadingButton
      data-trackable-action="Button Click"
      {...buttonProps}
      name={name}
      aria-label={name}
    >
      {icon && <Icon icon={icon} className="me-1" />}
      {children}
    </LoadingButton>
  );
};

KvButton.defaultProps = {
  name: undefined,
  disabled: false,
  useWrapper: false,
  isLoading: false,
  onClick: () => undefined,
  icon: undefined,
};

KvButton.propTypes = {
  disabled: PropTypes.bool,
  useWrapper: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  name: PropTypes.string,
  icon: PropTypes.string,
};

export default KvButton;
