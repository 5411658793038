import PropTypes from "prop-types";
import KvInput from "../shared/KvInput";
import { baseShape, folderShape, testSuiteShape } from "../shared/shapes";

const TestSelectFields = ({ item, onChange, selectedIds }) => (
  <li>
    <KvInput
      label={item.name}
      name={item.name}
      type="checkbox"
      defaultChecked={selectedIds.includes(item.id)}
      value={item.id}
      onChange={onChange}
      inline
      groupClassName="text-nowrap"
      className="me-2"
    />
  </li>
);

TestSelectFields.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  item: PropTypes.shape(baseShape).isRequired,
};

const SuiteTestSelectFields = ({ suite, ...rest }) => (
  <div key={suite.id}>
    <h4 className="fs-5">{suite.name}</h4>

    <ul className="ms-4 list-unstyled">
      {suite.tests?.map((t) => (
        <TestSelectFields {...{ key: t.id, item: t, ...rest }} />
      ))}
    </ul>
  </div>
);
SuiteTestSelectFields.propTypes = {
  suite: PropTypes.shape(testSuiteShape).isRequired,
};

const FolderTestSelectFields = ({ scope, folder, ...rest }) =>
  scope === "suites" ? (
    <ul className="ms-4 list-unstyled">
      {folder.testSuites?.map((s) => (
        <TestSelectFields {...{ key: s.id, item: s, ...rest }} />
      ))}
    </ul>
  ) : (
    <div>
      {folder.testSuites?.map((s) => (
        <SuiteTestSelectFields key={s.id} suite={s} {...rest} />
      ))}
    </div>
  );
FolderTestSelectFields.propTypes = {
  folder: PropTypes.shape(folderShape).isRequired,
  scope: PropTypes.oneOf(["tests", "suites"]).isRequired,
};

export default FolderTestSelectFields;
