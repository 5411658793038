import PropTypes from "prop-types";
import { Toast as BootToast, ToastBody, ToastHeader } from "reactstrap";

const Toast = ({ toast, onClose }) => {
  let { header, body } = toast;

  if (!header) {
    header = body;
    body = null;
  }

  return (
    <BootToast key={header}>
      <ToastHeader toggle={onClose} icon={toast.color}>
        {header}
      </ToastHeader>
      {body && <ToastBody>{body}</ToastBody>}
    </BootToast>
  );
};

const toastShape = PropTypes.shape({
  body: PropTypes.node,
  color: PropTypes.string,
  header: PropTypes.node,
});

Toast.propTypes = {
  toast: toastShape.isRequired,
  onClose: PropTypes.func.isRequired,
};

const Toasts = ({ toasts, onClose }) => {
  if (!toasts || !Object.keys(toasts).length) return "";

  return (
    <div className="kv-toast-container">
      {Object.entries(toasts).map(([id, toast]) => (
        <Toast key={id} toast={toast} onClose={() => onClose(id)} />
      ))}
    </div>
  );
};

Toasts.propTypes = {
  onClose: PropTypes.func.isRequired,
  toasts: PropTypes.objectOf(toastShape).isRequired,
};

export default Toasts;
