import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError as onApolloError } from "@apollo/client/link/error";
import { useToasts } from "./ToastContextProvider";
import { useAuth } from "../Components/Auth/Auth0/Auth0Wrapper";
import { stashLastLocation } from "../Components/Auth/Auth0/helpers";

const uri = [
  process.env.REACT_APP_API_HOST,
  process.env.REACT_APP_API_ENDPOINT,
].join("");

const initClient = ({ accessToken, onError, disabled }) => {
  const authLink = setContext((_, { headers }) => ({
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
      ...headers,
    },
  }));

  const apolloErrorLink = onApolloError(({ graphQLErrors }) => {
    graphQLErrors?.forEach(async (error) => {
      onError(error);

      if (error.extensions.code === "UNAUTHENTICATED") {
        if (!["/sign-out", "/status"].includes(window.location.pathname)) {
          stashLastLocation();
          setTimeout(() => {
            window.location.href = "/sign-out";
          }, 250);
        }
      } else if (error.extensions.code === "UNAUTHORIZED") {
        console.error("UNAUTHORIZED: ", error.message, JSON.stringify(error));
        if (error.message.match(/user.*does not exist/)) {
          setTimeout(() => {
            window.location.href = "/sign-out";
          }, 250);
        }
      }
    });
  });

  const client = new ApolloClient({
    link: ApolloLink.from([apolloErrorLink, authLink, new HttpLink({ uri })]),
    cache: new InMemoryCache(),
  });

  client.disableNetworkFetches = disabled;
  return client;
};

const ApolloWrapper = ({ children }) => {
  const { accessToken, publicRoute } = useAuth();
  const { addToast } = useToasts();
  const [client, setClient] = useState();

  const onError = (error) => {
    console.error("graphQL error:", error.extensions.code, error);

    // eslint-disable-next-line no-param-reassign
    error.toastId = addToast({
      header: "Error",
      body: error.message,
      color: "danger",
    });
  };

  useEffect(() => {
    if (accessToken || publicRoute) {
      const newClient = initClient({
        accessToken,
        onError,
      });
      setClient(newClient);
    } else if (client) {
      setClient(undefined);
    }
  }, [accessToken, publicRoute]);

  return client ? (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  ) : (
    ""
  );
};

ApolloWrapper.propTypes = { children: PropTypes.node.isRequired };

export default ApolloWrapper;
