import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import KvInput from "../shared/KvInput";
import { GET_ALL_FOLDERS } from "../Tests/Tests";

const FolderSelect = ({ includeBlank, ...inputProps }) => {
  const [folders, setFolders] = useState();
  const { data } = useQuery(GET_ALL_FOLDERS);

  useEffect(() => {
    if (data) setFolders(_.sortBy(data.getAllFolders, "name"));
  }, [data]);

  return (
    folders && (
      <KvInput type="select" {...inputProps} groupClassName="w-100">
        {includeBlank && <option value=""> – </option>}
        {folders.map((u) => (
          <option key={u.id} value={u.id}>
            {u.name}
          </option>
        ))}
      </KvInput>
    )
  );
};

FolderSelect.defaultProps = { includeBlank: true };
FolderSelect.propTypes = {
  includeBlank: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default FolderSelect;
