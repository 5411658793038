import dayjs from "dayjs";

export const displayDate = (date) => dayjs(date).format("DD MMM YYYY");

export const displayDateTimeSecs = (date) =>
  dayjs(date).format("YYYY-MM-DD hh:mm:ss a");

export const displayDateTime = (date) =>
  dayjs(date).format("YYYY-MM-DD hh:mm a");

export const pluralize = (count, noun, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;
