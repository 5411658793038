import gql from "graphql-tag";
import {
  TestResultFields,
  SuiteResultFields,
} from "../Tests/FolderReport/ResultTable";

export const GET_MANAGED_RUNS = gql`
  query GetAllManagedRuns {
    getAllManagedRuns {
      id
      name
      status
      createdAt
      folder {
        id
        name
      }
    }
  }
`;

export const GET_MANAGED_RUN = gql`
  query GetManagedRun($id: ID!) {
    getManagedRun(id: $id) {
      id
      name
      status
      testIds
      suiteIds
      testResults {
        ...TestResultFields
      }
      suiteResults {
        ...SuiteResultFields
        testResults {
          ...TestResultFields
        }
      }
      folder {
        id
        name
        testSuites {
          id
          name
          tests {
            id
            name
          }
        }
      }
    }
  }
  ${SuiteResultFields}
  ${TestResultFields}
`;

export const CREATE_MANAGED_RUN = gql`
  mutation CreateManagedRun($managedRun: ManagedRunInput!) {
    createManagedRun(managedRun: $managedRun) {
      id
      name
    }
  }
`;

export const UPDATE_MANAGED_RUN = gql`
  mutation UpdateManagedRun($id: ID!, $managedRun: ManagedRunInput!) {
    updateManagedRun(id: $id, managedRun: $managedRun) {
      id
      name
      testIds
      suiteIds
    }
  }
`;

export const START_MANAGED_RUN = gql`
  mutation startManagedRun($id: ID!) {
    startManagedRun(id: $id) {
      id
      name
      status
    }
  }
`;
