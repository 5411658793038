import { useState, createElement } from "react";
import PropTypes from "prop-types";
import SingleInputForm from "./SingleInputForm";
import { Icon } from ".";

const SingleEditableValue = ({ value, onSubmit, displayAs, name }) => {
  const [editEvalue, setEditValue] = useState(false);

  const toggleEditValue = (e) => {
    setEditValue((prev) => !prev);
    e.stopPropagation();
  };

  const wrappedOnSubmit = async (newValue) => {
    onSubmit(newValue);
    toggleEditValue();
  };

  return editEvalue ? (
    <SingleInputForm
      onSubmit={wrappedOnSubmit}
      onCancel={toggleEditValue}
      defaultValue={value}
      name={name}
    />
  ) : (
    <div className="d-flex align-items-center hover-children-visible">
      {createElement(displayAs, { className: "me-2 mb-0" }, value)}

      <Icon
        role="button"
        icon="Edit"
        onClick={toggleEditValue}
        className="ps-2 cursor-pointer kv-edit-icon invisible"
        aria-label="edit value"
      />
    </div>
  );
};

SingleEditableValue.defaultProps = {
  displayAs: "h2",
};

SingleEditableValue.propTypes = {
  value: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  displayAs: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default SingleEditableValue;
