import PropTypes from "prop-types";
import { Nav, NavItem } from "reactstrap";

const tabs = {
  suites: "Select Suites",
  tests: "Select Tests",
};

const Tabs = ({ tab, setTab }) => (
  <Nav tabs className="mb-4">
    {Object.entries(tabs).map(([key, label]) => (
      <NavItem key={key} onClick={() => setTab(key)} className="cursor-pointer">
        <div className={`nav-link ${tab === key ? "active" : ""}`}>{label}</div>
      </NavItem>
    ))}
  </Nav>
);

Tabs.propTypes = {
  setTab: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
};

export default Tabs;
